import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import { useSnackbar } from 'currenda-design-system';

import { loginService, refreshTokenService } from '~coreServices/kid-auth.service';
import { finishAttestationAuthService, startAttestationAuthService } from '~coreServices/fido.service';

import { authorizationCode, refreshBidderTokenService, register } from '@/../lk/services/auth.service';

import { useContextStore } from '~coreStores/context.store';

import { useBailiffAuthTokens } from '~coreComposables/use-bailiff-auth-tokens';
import { useEndSessionCountdown } from '~coreComposables/end-session-countdown.composable';
import { useBailiffSessionEnded } from '~coreComposables/bailiff-session-ended.composable';

import { decodeJWTToken, isTokenBailiff, isTokenBidder } from '~coreUtils/user-token';
import { arrayBufferToString, getCredentialsNavigator } from '~coreUtils/fido';

import {
  EIDAuthRefreshTokenStatus,
  ETwoAuthType,
  ELoginStep,
  type IAccessTokenBailiffOfficeUser,
  type ILogin,
  type IResponseRefreshBidderToken,
  type ITokenBidderUser,
  type IUserAuthStoreState,
  ELoginErrorStatus
} from '~coreTypes/user-auth.model';
import type { TErrorWithFetchError, TFetchError } from '~coreTypes/generic-state.model';
import type { IFinishAttestationAuth } from '~/types/fido.model';

// Moduł logowania dla użytkownika (komornik, licytant, pracownik kancelarii)
export const useUserAuthStore = defineStore('userAuth', {
  state: (): IUserAuthStoreState => {
    return {
      // dane zwracane przez usługę po zalogowaniu komornika
      bailiffOfficeLoginResponse: {
        data: null,
        error: null,
        errorMessage: null,
        pending: false
      },
      // dane zwracane przez usługę po zalogowaniu licytanta
      bidderLoginResponse: {
        data: null,
        error: null,
        pending: false
      },
      login: '', // login podczas logowania
      loginStep: ELoginStep.LOGIN, // krok logowania
      loginVerificationJWTToken: null, // token werfikacyjny podczas logowania
      password: '', // hasło logowania
      accessTokenBidder: '', // access token licytanta
      refreshTokenBidder: '', // refresh token licytanta
      showEndSessionDialog: false // widoczność dialogu z informacją o końcu sesji dla licytanta
    };
  },
  getters: {
    // zwraca dane zalogowanego komornika/pracownika kancelarii komorniczej
    getBailiffOfficeUserData(state) {
      return state.bailiffOfficeLoginResponse.data;
    },
    // zwraca dane zalogowanego licytanta
    getBidderUserData(state) {
      return state.bidderLoginResponse.data;
    },
    // zwraca dane zalogowanego użytkownika
    getUserData(state) {
      return state.bailiffOfficeLoginResponse.data || state.bidderLoginResponse.data;
    },
    getIsUserLoggedIn(state) {
      return state.bailiffOfficeLoginResponse.data || state.bidderLoginResponse.data ? true : false;
    }
  },
  actions: {
    // zwraca access i refresh token zależnie od typu użytkownika (komornik/pracownik kancelarii lub licytant) oraz rozkodowane dane z access tokena
    getAuthTokens() {
      const { accessTokenBailiff, refreshTokenBailiff } = useBailiffAuthTokens();

      // access token zależnie od typu użytkownika (komornik/pracownik kancelarii lub licytant)
      const accessToken = accessTokenBailiff.value ? accessTokenBailiff.value : this.accessTokenBidder;

      // rozkodowany access token
      const accessTokenDecoded = decodeJWTToken(accessToken);

      // refresh token zależnie od typu użytkownika (komornik/pracownik kancelarii lub licytant)
      const refreshToken = refreshTokenBailiff.value || this.refreshTokenBidder;

      // refresh token z usługi ELKRK nie da się rozkodować i musimy opierać na expired z  access tokena
      const refreshTokenDecoded = refreshTokenBailiff.value
        ? decodeJWTToken(refreshToken)
        : accessTokenDecoded
          ? { exp: accessTokenDecoded.exp }
          : null;

      return {
        accessToken,
        accessTokenDecoded,
        refreshToken,
        refreshTokenDecoded
      };
    },

    // czyści tokeny licytanta
    clearBiddersTokens() {
      this.accessTokenBidder = '';
      this.refreshTokenBidder = '';
    },

    // usuwa access i refresh tokeny licytanta i komornika/pracownika kancelarii komorniczej
    clearAllTokens() {
      const { clearBailiffAuthTokens } = useBailiffAuthTokens();

      clearBailiffAuthTokens();

      this.clearBiddersTokens();
    },

    // zapisuje access i refresh token do cookies
    saveTokens(accessToken: string | null, refreshToken: string | null) {
      // jeśli usługa nie zwróci access lub refresh tokena
      if (!accessToken || !refreshToken) throw new Error('Access lub refresh token jest pusty.');

      const { clearBailiffAuthTokens, setBailiffAuthTokens } = useBailiffAuthTokens();

      if (isTokenBailiff(accessToken)) {
        this.clearBiddersTokens();
        setBailiffAuthTokens(accessToken, refreshToken);
      } else if (isTokenBidder(accessToken)) {
        clearBailiffAuthTokens();
        this.accessTokenBidder = accessToken;
        this.refreshTokenBidder = refreshToken;
      }
    },

    /*
      obsługuje sytuacje gdy komornik lub pracownik kancelarii komorniczej wyłączy aplikacje, ale nie wyłączy
      przeglądarki i po ponownym otwarciu aplikacji token jest w ciasteczkach (sesji), ale jest nieważny
    */
    handleExpiredToken() {
      const { $routeNames } = useNuxtApp();

      const { accessToken, refreshTokenDecoded } = this.getAuthTokens();

      // brak tokena - przerywamy dalszą akcje
      if (!refreshTokenDecoded) return true;

      const now = dayjs().unix();

      // jeśli refresh token wygasł
      if (now >= refreshTokenDecoded.exp) {
        this.clearAllTokens();
        this.clearUserData();

        if (isTokenBailiff(accessToken)) {
          const contextStore = useContextStore();
          contextStore.clearContext();
          return navigateTo({ name: $routeNames.bailiff.login });
        } else if (isTokenBidder(accessToken)) {
          return navigateTo({ name: $routeNames.index });
        }

        return true;
      }

      // tokeń jest nadal aktywny (nie wygasł)
      return false;
    },

    // ustawia dane komornika/pracownika kancelarii komorniczej z tokena
    setBailiffOfficeUserDataFromToken() {
      const { accessToken, accessTokenDecoded } = this.getAuthTokens();

      if (!isTokenBailiff(accessToken)) return;

      const { user_email, user_name, employment } = accessTokenDecoded as IAccessTokenBailiffOfficeUser;

      if (!employment.length) return;

      this.bidderLoginResponse.data = null; // czyszczenie danych licytanta przed ustawianiem danych komornika
      this.bailiffOfficeLoginResponse.data = { name: user_name, email: user_email, contexts: employment };
    },

    // ustawia dane licytanta z tokena
    setBidderUserDataFromToken() {
      const { accessToken, accessTokenDecoded } = this.getAuthTokens();

      if (!isTokenBidder(accessToken)) return;

      const { user_id, user_email, user_fullname } = accessTokenDecoded as ITokenBidderUser;

      this.bailiffOfficeLoginResponse.data = null; // czyszczenie danych komornika przed ustawianiem danych licytanta
      this.bidderLoginResponse.data = { id: user_id, name: user_fullname, email: user_email };
    },

    // ustawia dane użytkownika w zależności od typu użytkownika analizując dane w tokenie
    setUserDataFromToken() {
      const isTokenExpired = this.handleExpiredToken();
      if (isTokenExpired) return;

      this.setBailiffOfficeUserDataFromToken();
      this.setBidderUserDataFromToken();
    },

    // ustawia krok logowania za pomocą kluczy - FIDO
    async setFidoStep(accessToken: string) {
      try {
        const startAttestationResponse = await startAttestationAuthService(accessToken);

        if (startAttestationResponse.response.status === 'ok') {
          const data = await getCredentialsNavigator(startAttestationResponse.response);

          if (!data) {
            const { $i18n } = useNuxtApp();
            const snackbar = useSnackbar();

            snackbar.create({
              message: $i18n.t('loginPage.fido.error'),
              timeout: 60000
            });

            return;
          }

          const keyResponse: IFinishAttestationAuth = {
            id: data.id,
            rawId: data.id,
            response: {
              authenticatorData: arrayBufferToString(data.response.authenticatorData),
              clientDataJSON: arrayBufferToString(data.response.clientDataJSON),
              signature: arrayBufferToString(data.response.signature)
            },
            extensions: {},
            type: data.type
          };

          const finishAttestationResponse = await finishAttestationAuthService(
            accessToken,
            keyResponse,
            startAttestationResponse.session
          );

          this.saveTokens(finishAttestationResponse.accessToken, finishAttestationResponse.refreshToken);
        }
      } catch (error) {
        const { $i18n } = useNuxtApp();
        const snackbar = useSnackbar();

        snackbar.create({
          message: $i18n.t('loginPage.fido.error'),
          timeout: 60000
        });
      }
    },

    setLoginStep(loginStep: ELoginStep, accessToken?: string) {
      if (accessToken && loginStep === ELoginStep.GOOGLE_AUTHENTICATOR) {
        // ustawiamy token weryfikacyjny po wprowadzeniu poprawnych danych logowania
        // aby móc zweryfikować kod weryfikacyjny za pomocą usługi POST /api/auth/login-google-auth potrzebujemy ustawić w nagłówku Authorization token JWT
        this.loginVerificationJWTToken = accessToken;
      } else {
        // gdy zmieniamy krok logowania na inny niż google authenticator to czyscimy token weryfikacyjny
        this.loginVerificationJWTToken = null;
      }

      this.loginStep = loginStep;
    },

    // logowanie komornika lub pracownika kancelarii komorniczej
    async bailiffSignIn(loginData: ILogin) {
      this.bailiffOfficeLoginResponse.pending = true;
      this.bailiffOfficeLoginResponse.error = null;
      this.bailiffOfficeLoginResponse.errorMessage = null;

      try {
        const { accessToken, refreshToken } = await loginService(loginData);

        // obsługa logowania 2FA
        if (accessToken && !refreshToken) {
          const accessTokenDecoded = decodeJWTToken(accessToken);

          if (!accessTokenDecoded) throw new Error('Wystąpił błąd podczas rozkodowania tokena');

          if ('two_auth_type' in accessTokenDecoded === false)
            throw new Error('Token nie posiada parametru two_auth_type');

          // Obsługa 2FA
          if (accessTokenDecoded.two_auth_type === ETwoAuthType.TWO_AUTH_GOOGLE_AUTHENTICATOR) {
            // jeśli użytkownik musi podać kod google authenticator
            this.setLoginStep(ELoginStep.GOOGLE_AUTHENTICATOR, accessToken);
            return;
          } else if (accessTokenDecoded.two_auth_type === ETwoAuthType.TWO_AUTH_FIDO) {
            // jeśli użytkownik musi włożyć klucz FIDO
            await this.setFidoStep(accessToken);
            return;
          }
        }

        this.saveTokens(accessToken, refreshToken);
      } catch (err: unknown) {
        const error = err as TFetchError;

        this.bailiffOfficeLoginResponse.error = error.data ?? null;
        this.bailiffOfficeLoginResponse.errorMessage = this.getBailiffSignInErrorMessage(error.data ?? null);
      } finally {
        this.bailiffOfficeLoginResponse.pending = false;
      }
    },

    // zwraca komunikat błędu logowania komornika/pracownika kancelarii komorniczej
    getBailiffSignInErrorMessage(error: TErrorWithFetchError) {
      const { $i18n } = useNuxtApp();

      if (!error) {
        return {
          title: $i18n.t('loginPage.error.title'),
          description: $i18n.t('loginPage.error.description')
        };
      }

      if ('errorResultEnum' in error) {
        if (
          [ELoginErrorStatus.WRONG_LOGIN_OR_PASSWORD, ELoginErrorStatus.REQUIRE_RESET_PASSWORD].includes(
            error.errorResultEnum
          )
        ) {
          // błąd związany z kontem komornika po stronie KID
          return {
            title: $i18n.t('loginPage.KIDError.title'),
            description: $i18n.t('loginPage.KIDError.description')
          };
        } else if (error.errorResultEnum === ELoginErrorStatus.NO_2FA) {
          return {
            title: $i18n.t('loginPage.NO2FA.title'),
            description: $i18n.t('loginPage.NO2FA.description')
          };
        }

        // inny błąd zdefiniowany przez usługę
        return {
          title: $i18n.t('loginPage.error.title'),
          description: $i18n.t('loginPage.error.description')
        };
      } else if (error?.status) {
        // nie zidentyfikowany błąd
        return {
          title: $i18n.t('loginPage.error.title'),
          description: $i18n.t('loginPage.error.description')
        };
      }

      return null;
    },

    // odświeżenie tokenów (jeśli są w trakcie wygasania)
    async refreshTokenAction() {
      try {
        const { refreshToken: validRefreshToken, accessTokenDecoded } = this.getAuthTokens();

        // aktualny refreshToken
        if (!validRefreshToken) return;

        if (!accessTokenDecoded) return;

        const config = useRuntimeConfig();

        // timestamp kolejnego odświeżenia tokenu
        const offsetIntervalSeconds = 10;
        const nextRefreshTokenTimestamp = dayjs()
          .add(config.public.refreshTokenIntervalTime + offsetIntervalSeconds, 'seconds')
          .unix();

        // jeśli token nie wygaśnie przed kolejnym odświeżeniem
        if (accessTokenDecoded.exp > nextRefreshTokenTimestamp) return;

        if (this.getBailiffOfficeUserData) {
          // odświeżanie tokena dla komornika/pracownika kancelarii komorniczej
          const { $i18n, $routeNames } = useNuxtApp();
          const contextStore = useContextStore();

          try {
            const { accessToken, refreshToken } = await refreshTokenService(validRefreshToken);

            if (!accessToken || !refreshToken) {
              this.logoutUser();

              throw new Error('Wystąpił problem przy odświeżaniu tokena');
            }

            this.saveTokens(accessToken, refreshToken);

            // jeśli w pobranym na nowo tokenie nie ma aktualnie wybranego kontekstu
            if (
              this.bailiffOfficeLoginResponse.data?.contexts.every(
                (context) => context.office_guid !== contextStore.activeContext?.office_guid
              )
            ) {
              // czyścimy aktywny kontekst i przekierowujemy do wyboru kontekstu
              contextStore.clearContext();
              navigateTo({ name: $routeNames.bailiff.context });
            }
          } catch (err) {
            const error = err as TFetchError<EIDAuthRefreshTokenStatus>;

            // jeśli na konto zalogowano się z innego urządzenia/przeglądarki
            if (error.status === 409 && error.data?.errorResultEnum === EIDAuthRefreshTokenStatus.CONFLICT) {
              const bailiffSessionEnded = useBailiffSessionEnded();
              bailiffSessionEnded.showEndSessionPopup($i18n.t('bailiff.logoutMessage'));

              this.logoutUser();
            }
          }
        } else if (this.getBidderUserData) {
          // odświeżanie tokena dla licytanta
          const { access_token, refresh_token } = (await refreshBidderTokenService(
            validRefreshToken
          )) as IResponseRefreshBidderToken;

          this.saveTokens(access_token, refresh_token);

          this.setBidderUserDataFromToken();
        }
      } catch (error: unknown) {
        throw new Error(JSON.stringify(error));
      }
    },

    // wylogowanie użytkownika
    async logoutUser() {
      const { clearActiveLogoutTimer } = useEndSessionCountdown();
      const { $routeNames } = useNuxtApp();

      if (this.getBailiffOfficeUserData) {
        this.clearAllTokens();
      } else if (this.getBidderUserData) {
        this.clearAllTokens();
        this.clearUserData();

        navigateTo({ name: $routeNames.index });
      }

      sessionStorage.removeItem('previousPathForBidder');
      clearActiveLogoutTimer();
    },

    // czyści dane użytkownika pinia state
    clearUserData() {
      this.bailiffOfficeLoginResponse.data = null;
      this.bidderLoginResponse.data = null;
    },

    async registerUser(data: any) {
      return await register(data);
    },

    sendAuthorizationCode(code: string) {
      const runtimeConfig = useRuntimeConfig();
      const contextStore = useContextStore();

      const formData = new FormData();
      formData.append('grant_type', 'authorization_code');
      formData.append('code', code);
      formData.append(
        'redirect_uri',
        `${runtimeConfig.public.appUrl}/${runtimeConfig.public.redirectUriForLoginAsBidder}`
      );

      return new Promise(async (resolve, reject) => {
        try {
          const token = await authorizationCode(formData);

          this.saveTokens(token.access_token, token.refresh_token);

          contextStore.clearContext();

          this.setBidderUserDataFromToken();

          resolve(token);
        } catch (error: any) {
          reject(error);
        }
      });
    }
  },
  ...(import.meta.client && {
    persist: {
      storage: sessionStorage,
      pick: ['accessTokenBidder', 'refreshTokenBidder']
    }
  })
});
