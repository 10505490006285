const contentSecurityPolicy =
  `default-src 'self' ${process.env.cspDomains} ` +
  "script-src 'report-sample' 'self' www.google-analytics.com/analytics.js www.googletagmanager.com/gtag/js " +
  "style-src 'report-sample' 'self' cdn.jsdelivr.net fonts.googleapis.com " +
  "object-src 'none' " +
  "base-uri 'self' " +
  `connect-src 'self' ${process.env.cspDomains} ` +
  "font-src 'self' cdn.jsdelivr.net cdn.linearicons.com fonts.gstatic.com " +
  "frame-src 'self' " +
  "img-src 'self' data: cdn3.iconfinder.com www.google-analytics.com " +
  "manifest-src 'self' " +
  "media-src 'self' " +
  'report-uri 6267d255063b0bf3ec040bce.endpoint.csper.io/?v=0 ' +
  "worker-src 'none'";

const permissionPolicy =
  'accelerometer=(), ambient-light-sensor=(), autoplay=(), battery=(), camera=(),' +
  'cross-origin-isolated=(), display-capture=(), document-domain=(), encrypted-media=(),' +
  'execution-while-not-rendered=(), execution-while-out-of-viewport=(), fullscreen=(), geolocation=(),' +
  'gyroscope=(), keyboard-map=(), magnetometer=(), microphone=(), midi=(), navigation-override=(), payment=(),' +
  'picture-in-picture=(), publickey-credentials-get=(), screen-wake-lock=(), sync-xhr=(), usb=(), web-share=(),' +
  'xr-spatial-tracking=(), clipboard-read=(), clipboard-write=(), gamepad=(), speaker-selection=(),' +
  'conversion-measurement=(), focus-without-user-activation=(), hid=(), idle-detection=(), interest-cohort=(),' +
  'serial=(), sync-script=(), trust-token-redemption=(), window-placement=(), vertical-scroll=()';

export {
  contentSecurityPolicy,
  permissionPolicy
};
