import { defineStore } from 'pinia';
import type { FetchError } from 'ofetch';
import { type SortT } from 'currenda-design-system/dist/types/components/data-display/data-table.types';

import { sortList } from '~coreUtils/sort';

import { fetchOffersForAuctionService } from '@Services/auctions/bailiff-auction-offers.service';

import type {
  IBailiffAuctionOffer,
  IBailiffAuctionOffersStoreState
} from '@Models/Auctions/BailiffAuctionOffers.model';
import { ESortDirection } from '~coreTypes/sort.model';

export const useBailiffAuctionOffersStore = defineStore('bailiffAuctionOffers', {
  state: (): IBailiffAuctionOffersStoreState => ({
    bailiffAuctionOffers: {
      data: null,
      error: null,
      pending: true
    }
  }),
  actions: {
    // sortuje listę ofert tabeli po stronie frontu
    sortOffers(event?: SortT) {
      if (!this.bailiffAuctionOffers.data) return;

      this.bailiffAuctionOffers.data = sortList<IBailiffAuctionOffer>(this.bailiffAuctionOffers.data, event, {
        key: 'bidValue',
        direction: ESortDirection.DESC
      });
    },

    // pobiera listę adnotacji dla danej aukcji
    async fetchAuctionOffers(auctionId: number) {
      try {
        this.bailiffAuctionOffers.pending = true;
        this.bailiffAuctionOffers.error = null;
        this.bailiffAuctionOffers.data = null;

        const response = await fetchOffersForAuctionService(auctionId);

        if (response) {
          this.bailiffAuctionOffers.data = response;
        }
      } catch (err) {
        const error = err as FetchError;
        this.bailiffAuctionOffers.error = error;
      } finally {
        this.bailiffAuctionOffers.pending = false;
      }
    }
  },
  getters: {
    getBailiffOffers: (state) => state.bailiffAuctionOffers
  }
});
