import dayjs from 'dayjs';

import { useBailiffSessionEnded } from '~coreComposables/bailiff-session-ended.composable';

import { useUserAuthStore } from '~coreStores/user-auth.store';

const state = reactive<{
  expirationTime: number | null;
}>({
  expirationTime: null // liczba sekund do wylogowania użytkownika,
});

let timer: NodeJS.Timeout | null = null;

export function useEndSessionCountdown() {
  const runtimeConfig = useRuntimeConfig();
  const sessionTimeoutInSeconds = runtimeConfig.public.sessionTimeoutInSeconds;
  const userAuthStore = useUserAuthStore();
  const { getUserData, getBailiffOfficeUserData } = storeToRefs(userAuthStore);
  const { expirationTime } = toRefs(state);
  const { $i18n } = useNuxtApp();

  const enteredAtTime = useCookie<number | null>('enteredAtTime', {
    secure: true,
    sameSite: 'strict',
    default() {
      return null;
    }
  });

  const useTwoDigits = (value: number) => {
    if (value.toString().length <= 1) {
      return '0' + value.toString();
    }
    return value.toString();
  };

  // zwraca informację o czasie do wylogowania
  const getExpirationTextInfo = computed(() => {
    if (!expirationTime.value) return;

    if (expirationTime.value < 1) return;

    return {
      label: $i18n.t('navbar.logoutTimeBailiff'),
      value: `${useTwoDigits(Math.trunc(expirationTime.value / 60) % 60)}:${useTwoDigits(expirationTime.value % 60)} min`
    };
  });

  // wyłącza odliczanie czasu do wylogowania
  function clearActiveLogoutTimer() {
    if (timer) clearInterval(timer);

    enteredAtTime.value = null;
    expirationTime.value = null;
  }

  function getActualTimeInSeconds() {
    return dayjs().unix();
  }

  // zwraca liczbe sekund pozostałych do wylogowania
  function calculateExpirationTime() {
    const actualTimeInSeconds = getActualTimeInSeconds();
    return sessionTimeoutInSeconds + ((enteredAtTime.value as number) - actualTimeInSeconds);
  }

  // wylogowuje użytkownika i pokazuje popup jeśli czas sesji się skończył
  function sessionExpiredHandler(expirationTime: number) {
    if (expirationTime <= 0) {
      if (getBailiffOfficeUserData.value) {
        const bailiffSessionEnded = useBailiffSessionEnded();
        bailiffSessionEnded.showEndSessionPopup($i18n.t('logoutModal.body'));
      } else {
        userAuthStore.showEndSessionDialog = true;
      }
      userAuthStore.logoutUser();
    }
  }

  // ustawia odliczanie czasu do wylogowania
  function setLogoutTimer() {
    if (!getUserData.value) return;

    clearActiveLogoutTimer();

    enteredAtTime.value = getActualTimeInSeconds();

    timer = setInterval(() => {
      expirationTime.value = calculateExpirationTime();

      sessionExpiredHandler(expirationTime.value);
    }, 1000);
  }

  function handleDocumentVisibilityChange() {
    if (!getUserData.value) return;

    if (!document.hidden) {
      const expirationTime = calculateExpirationTime();
      sessionExpiredHandler(expirationTime);
    }
  }

  return {
    expirationTime,
    getExpirationTextInfo,
    timer,
    clearActiveLogoutTimer,
    handleDocumentVisibilityChange,
    setLogoutTimer
  };
}
