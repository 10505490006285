import type { App, Plugin } from "vue"
import { components } from "currenda-design-system"
import type { CdsConfigI } from "currenda-design-system/dist/types/components.types"

import themeColors from "./theme/theme-colors.json" assert { type: "json" }
import themeColorOpacities from "./theme/theme-color-opacities.json" assert { type: "json" }

import "./scss/setup.scss"

export const register = (app: App, config?: CdsConfigI) => {
  app.use(
    components as unknown as Plugin,
    merge(
      { vuetify: { themes: { light: { colors: themeColors, variables: themeColorOpacities }, }, }, },
      config || {}
    )
  )
}

const merge = (t: any, s: any) => {
  for (const key of Object.keys(s)) {
    if (s[key] instanceof Object) {
      if (!t[key]) t[key] = {}
      Object.assign(s[key], merge(t[key], s[key]))
    }
  }
  Object.assign(t || {}, s)
  return t
}