export function useBailiffSessionEnded() {
  // Czy pokazać popup z informacją o wylogowaniu dla Komornika
  const endSessionPopupMessage = useCookie('endSessionPopupMessage', {
    secure: true,
    sameSite: 'strict'
  });

  const endSessionPopupVisibility = useCookie<boolean>('endSessionPopupVisibility', {
    secure: true,
    sameSite: 'strict',
    default: () => false
  });

  function showEndSessionPopup(msg: string) {
    endSessionPopupMessage.value = msg;
    endSessionPopupVisibility.value = true;
  }

  function hideEndSessionPopup() {
    endSessionPopupVisibility.value = false;
  }

  return {
    endSessionPopupMessage,
    endSessionPopupVisibility,
    hideEndSessionPopup,
    showEndSessionPopup
  };
}
