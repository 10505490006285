export enum EnumRoleName {
  bailiff = 'ROLE_BAILIFF'
}

export interface IContext {
  chamber_id: string;
  if_chamber_or_council_worker: boolean;
  locked: boolean;
  office_guid: string;
  office_name: string;
  role: EnumRoleName;
  role_name: string;
}

export type TContextsList = IContext[];

export interface ContextStoreStateI {
  activeContext: IContext | null;
}
