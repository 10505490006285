import { jwtDecode } from 'jwt-decode';

import {
  type IAccessTokenBailiffOfficeUser,
  type IVerificationTokenBailiffOfficeUser,
  type IRefreshTokenBailiffOfficeUser,
  type ITokenBidderUser
} from '~coreTypes/user-auth.model';

export function decodeJWTToken(token: string) {
  if (!token) return null;

  return jwtDecode<
    | IAccessTokenBailiffOfficeUser
    | IVerificationTokenBailiffOfficeUser
    | IRefreshTokenBailiffOfficeUser
    | ITokenBidderUser
  >(token);
}

// sprawdza czy token jest komornika
export function isTokenBailiff(token: string) {
  const decodedToken = decodeJWTToken(token);

  if (!decodedToken) return false;

  return 'employment' in decodedToken;
}

// sprawdza czy token jest licytanta
export function isTokenBidder(token: string) {
  const decodedToken = decodeJWTToken(token);

  if (!decodedToken) return false;

  return 'user_id' in decodedToken && decodedToken.user_id;
}
