import { defineStore } from 'pinia';
import type { FetchError } from 'ofetch';

import { fetchAuctionDetailsService, fetchBiddersForAuctionService } from '@Services/auctions/bailiff-auction.service';

import { EAuctionStatus } from '@Enums/auctionStatus.model';
import type { IBailiffAuctionBiddersQuery, IBailiffAuctionStoreState } from '@Models/Auctions/BailiffAuction.model';

export const useBailiffAuctionStore = defineStore('bailiffAuction', {
  state: (): IBailiffAuctionStoreState => ({
    bailiffAuction: {
      data: null,
      error: null,
      pending: false
    },
    bailiffAuctionBidders: {
      data: null,
      error: null,
      pending: false
    }
  }),
  actions: {
    // dynamiczna zmiana liczby skarg dla aukcji bez pobierania na nowo danych, używane np. w przypadku dodania skargi na przybicie
    changeCountOfComplaints(countOfComplaints: number) {
      if (!this.bailiffAuction.data) return;

      this.bailiffAuction.data.countOfComplaints = countOfComplaints;
    },

    // pobieranie szczegółów aukcji
    async fetchAuctionDetails(auctionId: number, showPendingState = true) {
      const stateKey = 'bailiffAuction';

      try {
        if (showPendingState) this[stateKey].pending = true;
        this[stateKey].error = null;

        const data = await fetchAuctionDetailsService(auctionId);

        this[stateKey].data = data;
      } catch (err: unknown) {
        const error = err as FetchError;
        this[stateKey].data = null;
        this[stateKey].error = error;
        throw new Error(JSON.stringify(error));
      } finally {
        if (showPendingState) this[stateKey].pending = false;
      }
    },
    // pobieranie licytantów dla aukcji
    // infiniteScroll - flaga informująca czy pobieranie danych ma być w trybie infinite scroll, jeśli false to pobierane dane nadpisują poprzednie (tabela)
    async fetchBiddersForAuction(payload: IBailiffAuctionBiddersQuery, infiniteScroll: boolean = false) {
      const stateKey = 'bailiffAuctionBidders';

      try {
        if (!infiniteScroll) this[stateKey].pending = true;
        this[stateKey].error = null;

        const data = await fetchBiddersForAuctionService(payload);

        if (infiniteScroll) {
          const previousBidders = this[stateKey].data?.bidders;

          this[stateKey].data = {
            ...data,
            bidders: [...(previousBidders || []), ...data.bidders]
          };
        } else {
          this[stateKey].data = data;
        }
      } catch (err: unknown) {
        const error = err as FetchError;
        this[stateKey].data = null;
        this[stateKey].error = error;
        throw new Error(JSON.stringify(error));
      } finally {
        if (!infiniteScroll) this[stateKey].pending = false;
      }
    }
  },
  getters: {
    getBailiffAuction: (state) => state.bailiffAuction,
    getBailiffAuctionData: (state) => state.bailiffAuction.data,
    getIsAuctionHasComplaints: (state) =>
      state.bailiffAuction.data?.countOfComplaints && state.bailiffAuction.data.countOfComplaints > 0 ? true : false,
    // czy aukcja nie jest zamknietą
    getBailiffAuctionIsNotClosed(state) {
      const auctionStatus = state.bailiffAuction.data?.auctionStatus;

      return (
        auctionStatus &&
        (auctionStatus === EAuctionStatus.CREATED ||
          auctionStatus === EAuctionStatus.IN_PROGRESS ||
          auctionStatus === EAuctionStatus.USED)
      );
    },
    getBailiffAuctionBidders: (state) => state.bailiffAuctionBidders
  }
});
