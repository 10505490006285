import type { RouteRecordName, RouteLocationRaw } from 'vue-router';

export enum EnumWorkingItemsCategoryId {
  REAL_ESTATE = 1,
  MOVABLE = 2,
  OTHERS = 3
}

type TSubNavItem = {
  pageTitle: string;
  text: string;
  to: RouteLocationRaw;
  toWithoutLocale: RouteLocationRaw;
  isDisabled?: boolean;
};

export type TNavItem = {
  children?: TSubNavItem[];
  count?: number;
  isDisabled?: boolean;
  routeName?: RouteRecordName;
  text: string;
  to?: TSubNavItem['to'];
};
