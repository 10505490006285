enum EActionType {
  CONFIRM_MARGIN_PAYMENT = 'CONFIRM_MARGIN_PAYMENT',
  CONFIRM_ITEM_PAYMENT = 'CONFIRM_ITEM_PAYMENT',
  CONFIRM_PRINCIPAL = 'CONFIRM_PRINCIPAL',
  CONFIRM_OTHER_DOCUMENT = 'CONFIRM_OTHER_DOCUMENT',
  EXCLUSION = 'EXCLUSION',
  KNOCK_DOWN = 'KNOCK_DOWN',
  REMIT_TO_COURT = 'REMIT_TO_COURT',
  COMPLAINT_RECOGNIZE = 'COMPLAINT_RECOGNIZE',
  COMPLAINT_DECISION = 'COMPLAINT_DECISION',
  CONFIRM_WAIVER = 'CONFIRM_WAIVER',
  CONFIRM_OTHER_DOCUMENT_ORIGINAL = 'CONFIRM_OTHER_DOCUMENT_ORIGINAL',
  CONFIRM_PRINCIPAL_ORIGINAL = 'CONFIRM_PRINCIPAL_ORIGINAL'
}

export { EActionType };
