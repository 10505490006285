import type { IDTO } from '~coreTypes/dto.model';
import type {
  IBailiffAuctionAnnotation,
  IBailiffAuctionBidders,
  IBailiffAuctionBiddersQuery,
  IBailiffSingleAuction,
  IPayloadAddAuctionAnnotation,
  IPayloadTransmitToCourt
} from '@Models/Auctions/BailiffAuction.model';
import { EDownloadHistory } from '@Models/Bailiff/downloadHistory.model';

import { base64EncodeUnicode } from '~coreUtils/base64';

export function fetchAuctionDetailsService(auctionId: number) {
  const { $lkItemBackFetch } = useNuxtApp();
  return $lkItemBackFetch<IBailiffSingleAuction>(`rest/item/bailiff/item_data/${auctionId}`, {
    method: 'GET'
  });
}

export function fetchBiddersForAuctionService(query: IBailiffAuctionBiddersQuery) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IBailiffAuctionBidders>(`rest/bid/auction/bidders/search`, {
    method: 'GET',
    query
  });
}

export function fetchAnnotationsForAuctionService(auctionId: number) {
  const { $lkItemBackFetch } = useNuxtApp();
  return $lkItemBackFetch<IBailiffAuctionAnnotation[]>(`rest/item/${auctionId}/get-annotation`, {
    method: 'GET'
  });
}

export function addAnnotationService(body: IPayloadAddAuctionAnnotation) {
  const { $lkItemBackFetch } = useNuxtApp();
  return $lkItemBackFetch<IBailiffAuctionAnnotation>('rest/item/add-annotation', {
    method: 'POST',
    body
  });
}

export function makeSealService(itemId: number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>(`rest/bid/item/${itemId}/knock-down`, {
    method: 'POST'
  });
}

// w przypadku sukcesu wracany jest Blob, w przypadku błędu objekt IDTO
export function downloadHistoryPdfService(itemId: number) {
  const { $lkItemBackFetch } = useNuxtApp();
  return $lkItemBackFetch<Blob | IDTO<boolean, EDownloadHistory>>(`rest/item/xml-pdf?itemId=${itemId}`, {
    method: 'GET'
  });
}

// w przypadku sukcesu zwracany XML, w przypadku błędu objekt IDTO
export function downloadHistoryXmlService(itemId: number) {
  const { $lkItemBackFetch } = useNuxtApp();
  return $lkItemBackFetch<Blob | IDTO<null, EDownloadHistory>>(`rest/item/${itemId}/xml`, {
    method: 'GET'
  });
}

// pobiera protokół licytacji
export function downloadAuctionProtocolService(itemId: number, signal?: AbortSignal) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<Blob | IDTO<null, string>>(`rest/bid/auction-protocol?itemId=${itemId}`, {
    method: 'GET',
    signal
  });
}

// odmowa przybicia
export function refuseSealService(itemId: number, comment: string) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('rest/bid/item/refuse-knock-down', {
    method: 'POST',
    body: {
      itemId: itemId,
      base64Comment: base64EncodeUnicode(comment)
    }
  });
}

// odmowa przybicia po przybciu
export function refuseSealKnockedService(itemId: number, comment: string) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('rest/bid/item/knocked-down-refuse', {
    method: 'POST',
    body: {
      itemId: itemId,
      base64Comment: base64EncodeUnicode(comment)
    }
  });
}

export function registerKnockDownComplaint(name: string, auctionId: number, attachments: File[]) {
  const { $lkBiddingFetch } = useNuxtApp();

  const formData = new FormData();

  formData.append('complainantName', name);
  formData.append('itemId', `${auctionId}`);

  attachments.forEach((attachment) => {
    formData.append('attachments', new File([attachment], attachment.name, { type: attachment.type }));
  });

  return $lkBiddingFetch<IDTO<boolean, string>>('rest/bid/knock-down-complaint', {
    method: 'POST',
    body: formData
  });
}

export async function transmitToCourtService(body: IPayloadTransmitToCourt) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('rest/bid/transfer-to-court', {
    method: 'PATCH',
    body
  });
}
