import { type DataTableSortT } from 'currenda-design-system/dist/types/components/data-display/data-table.types';
import { type TSortNotNullable, ESortDirection } from '~/types/sort.model';

// funkcja reużywalna do sortowania danych - głownie w miejscach korzystających z komponentu CdsDataTable
export function sortList<DataT>(
  data: DataT[], // dane do sortowania
  event?: DataTableSortT, // event sortowania z CdsDataTable
  defaultSortData?: { key: keyof DataT; direction: TSortNotNullable } // dane domyślnego sortowania
) {
  return data.toSorted((a, b) => {
    if (event) {
      const eventKey = event.key as keyof DataT;
      if (typeof a[eventKey] === 'number' && typeof b[eventKey] === 'number') {
        // sortowanie po numerze
        if (event.direction === ESortDirection.ASC) return a[eventKey] - b[eventKey];
        if (event.direction === ESortDirection.DESC) return b[eventKey] - a[eventKey];
      } else if (typeof a[eventKey] === 'string' && typeof b[eventKey] === 'string') {
        // sortowanie po stringu
        if (event.direction === ESortDirection.ASC) return a[eventKey].localeCompare(b[eventKey]);
        if (event.direction === ESortDirection.DESC) return b[eventKey].localeCompare(a[eventKey]);
      }
    }

    // domyślne sortowanie
    if (defaultSortData) {
      const { direction, key } = defaultSortData;

      if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        if (direction === ESortDirection.ASC) return a[key] - b[key];
        if (direction === ESortDirection.DESC) return b[key] - a[key];
      } else if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        if (direction === ESortDirection.ASC) return a[key].localeCompare(b[key]);
        if (direction === ESortDirection.DESC) return b[key].localeCompare(a[key]);
      }
    }

    return 0;
  });
}
