// Middleware służacy do resetowania wybranych stanów aplikacji komornika gdy przechodzimy zmienić kontekst lub wchodzimy na strone logowania

import { clearBailiffAuctionsState } from '@Composables/bailiff-auctions-list.composable';

import { useBailiffAuctionStore } from '@Store/bailiff-auction.module';
import { useBailiffAuctionAnnotationsStore } from '@Store/bailiff-auction-annotations.module';
import { useBailiffAuctionBidderStore } from '@Store/bailiff-auction-bidder.module';
import { useBailiffAuctionOffersStore } from '@Store/bailiff-auction-offers.module';

import { shouldClearState } from '~coreUtils/clear-state';

export default defineNuxtRouteMiddleware(async (to) => {
  if (shouldClearState(to.name as string)) {
    const storesToReset = [
      useBailiffAuctionStore(),
      useBailiffAuctionAnnotationsStore(),
      useBailiffAuctionBidderStore(),
      useBailiffAuctionOffersStore()
    ];

    storesToReset.forEach((store) => {
      store.$reset();
    });

    clearBailiffAuctionsState();
  }
});
