export const useBase64: () => any = () => {
  const base64DecodeUnicode: (text: string) => string = (text) => {
    if (text) {
      return decodeURIComponent(
        atob(text)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
    }
    return '';
  };

  const b64EncodeUnicode: any = (str: string) => {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(parseInt('0x' + p1));
        }
      )
    );
  };

  return {
    base64DecodeUnicode,
    b64EncodeUnicode
  };
};
