import { v4 as uuidv4 } from 'uuid';

import { useHttpService } from '@/composables/httpService';

export default defineNuxtPlugin(async () => {
  try {
    const runtimeConfig = useRuntimeConfig();
    const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/public/info?test=plk-appclient`;
    const service = useHttpService();

    const response = await service.useGet(url);

    sessionStorage.setItem('systemVersion', response.systemVersion);

    if (typeof runtimeConfig.public.environment === 'string')
      sessionStorage.setItem('environment', runtimeConfig.public.environment);

    if (!localStorage.getItem('fingerPrint')) {
      const { b64EncodeUnicode } = useBase64();
      const fingerPrint = `${uuidv4()}-${b64EncodeUnicode('elicytacje')}`;
      localStorage.setItem('fingerPrint', fingerPrint);
    }
  } catch (error) {}
});
