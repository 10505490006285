import { useErrorHandler } from '@Composables/errorHandler';

import { useToasts } from '@Composables/toast';
import { useHttpHeadersService } from '@Composables/httpHeadersService';

export const useHttpService: () => any = () => {
  const { $i18n } = useNuxtApp();
  const { translateError } = useErrorHandler();
  const { setErrorToast } = useToasts();

  const { addCommonHeaders, removeHeaderParam } = useHttpHeadersService();
  let headers: any = {};
  const excludedPaths: Array<string> = [
    'regulations-lists',
    'private-policy-lists',
    'manual-introductions',
    'manual',
    'faq',
    'topics',
    'trans.do'
  ];

  const usePost = async (url: string, body: any, newHeaders?: any, showToast: boolean = true) => {
    headers = addCommonHeaders(newHeaders);
    const requestOption = {
      method: 'POST',
      headers,
      body
    };

    return await fetch(url, requestOption).then((data) => {
      return handleResponse(data, showToast);
    });
  };

  const useGet = async (url: string, params?: any, showToast: boolean = true) => {
    headers = addCommonHeaders();
    headers = removeHeaderParam(url, headers);
    const requestOption = {
      method: 'GET',
      headers: headers,
      params: params ? JSON.stringify(params) : null
    };

    if (params) {
      url += '?' + new URLSearchParams(params).toString();
    }

    try {
      const response = await fetch(url, requestOption);
      return await handleResponse(response, showToast);
    } catch {} // improve
  };

  const useRawGet = async (url: string, params = {}) => {
    headers = addCommonHeaders();
    const requestOption = {
      method: 'GET',
      headers: headers
    };
    url += '?' + new URLSearchParams(params).toString();

    return await fetch(url, requestOption).then((data) => {
      return handleResponse(data);
    });
  };

  const usePatch = async (url: string, params?: any) => {
    headers = addCommonHeaders();
    let options = {
      method: 'PATCH',
      body: params ? JSON.stringify(params) : null,
      headers: headers
    };

    return await fetch(url, options).then((data) => {
      return handleResponse(data);
    });
  };

  const usePut = async (url: string, params: any) => {
    headers = addCommonHeaders();
    let options = {
      method: 'PUT',
      body: params ? JSON.stringify(params) : null,
      headers: headers
    };

    return await fetch(url, options).then((data) => {
      return handleResponse(data);
    });
  };

  const useDelete = async (url: string, params: any, showToast?: false) => {
    headers = addCommonHeaders();
    headers = removeHeaderParam(url, headers);
    let options = {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(params)
    };

    return await fetch(url, options).then((data) => {
      return handleResponse(data, showToast);
    });
  };

  const usePostForFormData = async (url: string, params: any = {}, newHeaders?: any) => {
    headers = addCommonHeaders(newHeaders);
    headers = removeHeaderParam(url, headers);
    let options = {
      method: 'POST',
      body: params,
      headers
    };

    delete options.headers['Content-Type'];

    return await fetch(url, options).then((data) => {
      return handleResponse(data, data.status === 400);
    });
  };

  const useGetFile = async (url: string) => {
    headers = addCommonHeaders();
    headers = removeHeaderParam(url, headers);
    let options = {
      method: 'GET',
      responseType: 'arraybuffer',
      headers: headers
    };

    return fetch(url, options)
      .then((response: any) => {
        if (response.status != 200) {
          return false;
        }
        const responsePromise = response.arrayBuffer();
        return responsePromise;
      })
      .catch((error) => {
        return false;
      });
  };

  // pobieranie pliku i zwraca error state z backendu
  const useGetFileWithErrorData = async (url: string) => {
    headers = addCommonHeaders();
    headers = removeHeaderParam(url, headers);
    let options = {
      method: 'GET',
      responseType: 'arraybuffer',
      headers: headers
    };

    return fetch(url, options)
      .then(async (response: any) => {
        if (response.status != 200) {
          return response.json();
        }
        const responsePromise = response.arrayBuffer();
        return responsePromise;
      })
      .catch((error) => {
        return error;
      });
  };

  const useGetFileRaw = async (url: string, params: any | null = null) => {
    headers = addCommonHeaders();
    headers = removeHeaderParam(url, headers);
    let options = {
      method: 'GET',
      responseType: 'arraybuffer',
      headers: headers,
      params: params ?? null
    };

    return fetch(url, options)
      .then((response: any) => {
        if (response.status != 200) {
          return false;
        }
        const responsePromise = response.arrayBuffer();
        return responsePromise;
      })
      .catch((error) => {
        return false;
      });
  };

  const handleResponse = async (response: any, showToast: boolean = true) => {
    if (response.status === 204) {
      return response;
    }
    return response.ok ? await handleResponseData(response) : await handleError(response, showToast);
  };

  async function handleResponseData(response: any) {
    const data = await response.text();
    return data ? JSON.parse(data) : true;
  }

  const handleError = async (error: any, showToast: boolean) => {
    if (error.status === 404) {
      return setErrorToast($i18n.t('notification.error.message'));
    }
    let errorData;
    try {
      errorData = await error.json();
    } catch (err) {
      return error.status;
    }

    if (errorData?.message) {
      return setErrorToast(errorData.message);
    } else if (errorData?.status) {
      return setErrorToast(errorData.status);
    } else if (error?.status === 500 && errorData?.url !== '' && errorData?.uuid !== '') {
      //for deleting account
      translateError($i18n.t('validation.general'));
      return false;
    } else if (showToast && typeof errorData.error === 'string' && errorData.error) {
      // globalna obsługa tłumaczenia błędów jeśli backend zwróci klucz error, który jest stringiem i nie jest pusty
      translateError(errorData.error);
      return false;
    }

    const caughtError = {
      status: error.status,
      data: errorData
    };

    if (showToast) translateError($i18n.t('errors.somethingWrong'));

    return caughtError;
  };

  return {
    useGetFile,
    useGetFileWithErrorData,
    usePostForFormData,
    useDelete,
    usePut,
    usePatch,
    useRawGet,
    useGet,
    usePost
    // translateError
  };
};
