<script setup lang="ts">
// Customowa strona błędu - @TODO ostylować zgodnie z makietą - na ten moment brak makiety.

import type { NuxtError } from '#app';

defineProps({
  error: Object as () => NuxtError
});

const handleError = () => clearError({ redirect: '/' });
</script>

<template>
  <div class="error">
    <div class="error__content">
      <CdsText
        variant="normal-h1"
        class="w-100"
        >{{ $t('errorPage.title') }} {{ error?.statusCode }}</CdsText
      >
      <CdsText
        variant="normal-body"
        class="w-100"
        >{{ error?.statusMessage || error?.message }}</CdsText
      >
      <div class="w-100">
        <CdsBtn
          size="regular"
          @click="handleError"
        >
          {{ $t('errorPage.button') }}
        </CdsBtn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  &__content {
    width: 800px;
    text-align: center;
    gap: cdsSpace('md');
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
