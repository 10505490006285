import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();

  if (!runtimeConfig.public.recaptchaOn) return;

  vueApp.use(VueReCaptcha, {
    siteKey: runtimeConfig.public.recaptchaSiteKey,
    loaderOptions: {
      autoHideBadge: true
    }
  });
});
