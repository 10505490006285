import type {
  IConfirmItemPaymentBody,
  IConfirmNoItemPaymentBody,
  IJoinInfo,
  WaiverInfo
} from '@Models/Auctions/BailiffAuctionBidder.model';
import type { IRequest, IRequestResponse } from '@Models/Bailiff/request.model';
import type { IDTO } from '~coreTypes/dto.model';
import type { IExcludeBidder, IExclusionReason, IExclusionComment } from '@Models/exclusion.model';

// pobieranie informacji o licytancie w danej aukcji
export function fetchBailiffAuctionBidderService(itemId: number, bidderId: number) {
  const { $lkBiddingFetch } = useNuxtApp();

  return $lkBiddingFetch<IJoinInfo>('rest/bid/bailiff/join-info', {
    method: 'GET',
    query: {
      itemId,
      registryUnitId: bidderId
    }
  });
}

//wysyłanie wezwań o rękojmie
export function sendRequestMarginMessage(data: IRequest) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<IRequestResponse>>('rest/bidder-message/request-for-margin-payment', {
    method: 'POST',
    body: {
      ...data
    }
  });
}

// wysyłanie wezwania o przedłożenie oryginałów
export function sendRequestOriginalDocsMessage(data: IRequest) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<IRequestResponse>>('rest/bidder-message/request-for-original', {
    method: 'POST',
    body: {
      ...data
    }
  });
}

//wysyłanie wezwań o dokumenty i pełnomocnictwa
export function sendRequestMessage(data: IRequest) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<IRequestResponse>>('/rest/bidder-message/add-message', {
    method: 'POST',
    body: {
      ...data
    }
  });
}

export function sendRequestMarginConfirmation(data: { accept: boolean; itemId: number; userId: number }) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('/rest/bid/accept-margin-bailiff', {
    method: 'POST',
    body: {
      ...data
    }
  });
}

export function sendRequestDocumentConfirmation(data: { itemId: number; registryUnitId: number }) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('/rest/bid/bidder/accept-documents-request', {
    method: 'PATCH',
    body: {
      ...data
    }
  });
}

// zatwierdza oryginały dokumentów/pełnomocnictw
export function confirmOriginalsService(bidderMessageId: number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>(`/rest/bidder-message/${bidderMessageId}/confirm`, {
    method: 'PATCH'
  });
}

export function sendRequestPowerOfAttorneyConfirmation(data: {
  accept: boolean;
  comment: null | string;
  itemId: number;
  userId: number;
}) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('/rest/bid/principal-confirm', {
    method: 'POST',
    body: {
      ...data
    }
  });
}

// potwierdzenie wpłaty za przedmiot
export async function confirmItemPaymentService(body: IConfirmItemPaymentBody) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('/rest/bid/accept-item-payment', {
    method: 'POST',
    body
  });
}

// potwierdzenie braku wpłaty
export async function confirmNoItemPaymentService(body: IConfirmNoItemPaymentBody) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>('/rest/bid/item-payment-refuse', {
    method: 'PATCH',
    body
  });
}

// pobieranie załącznika
export function fetchBidderAttachmentService(attachmentId: number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<BlobPart>(`rest/bid/bidder/attachment/${attachmentId}`, {
    method: 'GET',
    responseType: 'arrayBuffer'
  });
}

//potwierdzenie zrzeczenia
export function confirmWaiverByBailiff(params: { confirmed: boolean; itemId: number }) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<WaiverInfo>>('/rest/bid/user-complaint/confirm-waiver', {
    method: 'PATCH',
    body: params
  });
}

//pobierania powodów odmowy dopuszczenia
export function fetchExclusions() {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IExclusionReason[]>('/rest/bid/exclude/excludeReasons', {
    method: 'GET'
  });
}

//wykluczenie licytanta
export function excludeBidder(payload: IExcludeBidder) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<number>>('/rest/bid/exclude/excludeUser', {
    method: 'POST',
    query: {
      userId: payload.userId,
      itemId: payload.itemId
    },
    body: payload.data
  });
}

//pobieranie szczegółów wykluczenia
export function fetchExcludedDetails(itemId: number, registryUnitId: number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IExclusionComment>('/rest/bid/exclude/getCommentsUser', {
    method: 'GET',
    query: {
      itemId,
      registryUnitId
    }
  });
}

//cofanie wykluczenia
export function removeExclusion(payload: IExcludeBidder) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IDTO<boolean>>(`/rest/bid/exclude/removeExclusionUser`, {
    method: 'POST',
    query: {
      itemId: payload.itemId,
      userId: payload.userId
    },
    body: payload.data
  });
}
