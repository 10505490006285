enum authenticationStatus {
  USER_NULL = 'user-null',
  BAD_HEADERS = 'bad-headers',
  BAD_PARAMETERS = 'bad-parameters',
  WRONG_ACTION = 'wrong-action',
  BAD_REFRESH_TOKEN = 'bad-refresh-token',
  TOKEN_NOT_EXIST = 'token-not-exist',
  TOKEN_NOT_VALID_VALUES = 'token-not-valid-values',
  PASSWORD_EXPIRED = 'password-expired',
  CAPTCHA = 'captcha',
  ERROR = 'error',
  FORBIDDEN_CONNECT = 'forbidden-connect',
  UNKNOWN = 'unknown',
  WRONG_KID_ROLE = 'wrong-kid-role',
  LOGIN_LIMIT = 'login-limit',
  BAD_CREDENTIALS = 'bad-credentials',
  EMPTY_KID_RESPONSE = 'empty-kid-response',
  WRONG_DEVICE = 'wrong-device',
  NOT_UNIQUE_DEVICE_NAME = 'not-unique-device-name',
  WRONG_PIN = 'wrong-pin',
  EMPTY_DEVICE_NAME = 'empty-device-name',
  PIN_REQUIRED = 'pin-required',
  TOKEN_NOT_FOUND = 'token-not-found',
  USER_HAS_NOT_ENABLED_2FA = 'user-has-not-enabled-2fa',
  DISABLE_2FA_HAS_ALREADY_BEEN_CONFIRMED = 'disable-2fa-has-already-been-confirmed',
  TOKEN_EXPIRED = 'token-expired',
  WRONG_FINGERPRINT = 'wrong-fingerprint',
  WRONG_SYSTEM_VERSION = 'wrong-system-version',
  AUCTION_IS_NOT_EVENTUATED = 'auction-is-not-eventuated',
  AUCTION_SHOULD_HAVE_STATUS_CLOSED = 'auction-should-have-status-closed',
  AUCTION_CANNOT_BE_ORIDINARY_REAL_ESTATE = 'auction-cannot-be-oridinary-real-estate',
  USER_IS_NOT_WINNER = 'user-is-not-winner',
  UNAUTHORIZED = 'unauthorized',
  MARGIN_PAYMENT_DEADLINE_HAS_EXPIRED = 'margin-payment-deadline-has-expired',
  WAIVER_NOT_FOUND = 'waiver-not-found',
  HISTORY_DOWNLOAD_NOT_ALLOWED = 'history-download-not-allowed',
  UNAUTHORIZED_CLIENT = 'unauthorized_client',
  CAPTCHA_VALIDATION_FAILED = 'captcha-validation-failed',
  BAILIFF_NOT_OWNER = 'bailiff-not-owner',
  HISTORY_DOWNLOAD_NOT_AVAILABLE = 'history-download-not-available',
  ATTACHMENT_SAVE_ERROR = 'attachment-save-error',
  FIDO_REQUIRED = 'fido-required',
  BAD_RESPONSE_FROM_KID = 'bad-response-from-KID',
  ENABLED_FIDO = 'enabled_fido',
  USER_HAS_EXISTING_FIDO_CREDENTIAL = 'user-has-existing-fido-credential',
  TWO_FACTOR_AUTH_REQUIRED = 'two-factor-auth-required',
  ACCOUNT_NOT_CONFIRMED = 'account-not-confirmed',
  INVALID_GRANT = 'invalid_grant'
}

export default authenticationStatus;
