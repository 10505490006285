export function usePageKey() {
  // klucz potrzebny do odświeżania licznika do wylogowania na innych tabach przeglądarki
  const pageKey = useCookie('pageKey', {
    secure: true,
    sameSite: 'strict',
    default() {
      return 0;
    }
  });

  function incrementPageKey() {
    pageKey.value++;
  }

  return {
    pageKey,
    incrementPageKey
  };
}
