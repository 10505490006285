import { useHttpService } from '@Composables/httpService';
import { useHttpServiceV2 } from '@Composables/httpServiceV2';
import { useFetchService } from '@Composables/fetchService';

import type { IGenericModel } from '@Models/generic.model';

export async function verifyChangedPassword(token: string) {
  const { $lkBackOfficeFetch } = useNuxtApp();
  // @TODO TYPE any
  return $lkBackOfficeFetch<IGenericModel<any>>('rest/user/confirmupdate', {
    method: 'GET',
    query: {
      token
    }
  });
}

export async function getResetPasswordToken(token: string) {
  const { $lkBackOfficeFetch } = useNuxtApp();
  return $lkBackOfficeFetch<IGenericModel<boolean>>('rest/user/reset-password/check-token', {
    method: 'GET',
    params: {
      token
    }
  });
}

export async function userData() {
  const runtimeConfig = useRuntimeConfig();
  const { useGetV2 } = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/user/basic`;

  return await useGetV2(url);
}

export async function login(data: { body: any; headers: any }) {
  const runtimeConfig = useRuntimeConfig();
  const { usePost } = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.loginServer}/oauth2/token`;

  return await usePost(url, data.body, data.headers.headers, false);
}

export async function register(data: { body: any; headers: any }) {
  const runtimeConfig = useRuntimeConfig();
  const { post } = useFetchService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/user/account/request`;
  return post(url, data.body, data.headers, false);
}

export async function remindLogin(body: any, headers: any) {
  const runtimeConfig = useRuntimeConfig();
  const { usePost: usePostV2 } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/user/forgot-login`;

  await usePostV2(url, body, headers);
}

export async function remindPassword(body: any, headers: any) {
  const runtimeConfig = useRuntimeConfig();
  const { usePost: usePostV2 } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/user/forgot-password`;

  await usePostV2(url, body, headers);
}

export async function resetPassword(body: any, headers: any) {
  const runtimeConfig = useRuntimeConfig();
  const { usePost: usePostV2 } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/user/reset-password`;

  await usePostV2(url, body, headers);
}

export async function authorizationCode(body: any, header: any = {}) {
  const runtimeConfig = useRuntimeConfig();
  const { useAuthorization } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.loginServer}/oauth2/token`;

  return await useAuthorization(url, body, header);
}

export async function refreshBidderTokenService(token: string) {
  const runtimeConfig = useRuntimeConfig();
  const { useAuthorization } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.loginServer}/oauth2/token`;

  const body = new FormData();
  body.append('grant_type', 'refresh_token');
  body.append('refresh_token', token);

  return await useAuthorization(url, body);
}

export async function logout() {
  const runtimeConfig = useRuntimeConfig();
  const { useGet: useGetV2 } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.loginServer}/logout`;
  return useGetV2(url);
}

export async function fetchApprovals(context: any) {
  const runtimeConfig = useRuntimeConfig();
  const { useGet: useGetV2 } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/approvals/${context}`;
  return useGetV2(url);
}

export async function verifyRegistration(token: string) {
  const runtimeConfig = useRuntimeConfig();
  const { useGet: useGetV2 } = useHttpServiceV2();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.backOfficeServiceLK}/rest/user/account/verify-registration?token=${token}`;
  return useGetV2(url);
}
