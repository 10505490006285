import { useErrorHandler } from '@Composables/errorHandler';
import { useToasts } from '@Composables/toast';
import { useHttpHeadersService } from '@Composables/httpHeadersService';

export const useFetchService: () => any = () => {
  const { setErrorToast } = useToasts();
  const { translateError } = useErrorHandler();

  const { addCommonHeaders, removeHeaderParam } = useHttpHeadersService();
  let headers: any = {};

  const handleError = (error: any, showToast: boolean) => {
    if (error?.data) {
      const errorData = error.data;
      if (errorData?.message) {
        return setErrorToast(errorData.message);
      } else if (errorData?.status) {
        setErrorToast(errorData.status);
      } else if (errorData?.error) {
        translateError(errorData.error);
      }
      const caughtError = {
        status: error?.status,
        data: errorData
      };
      if (showToast) translateError(errorData.error);
      return caughtError;
    } else if (error?.error) {
      return error.error;
    } else {
      return error.status;
    }
  };

  const usePostFormData = async (
    url: string,
    params: any,
    newHeaders: any,
    showToast: boolean = false
  ): Promise<any> => {
    const headers = addCommonHeaders(newHeaders);
    delete headers['Content-Type'];
    try {
      const data = await $fetch(url, {
        method: 'POST',
        body: params,
        headers: headers
      });
      return data;
    } catch (error: any) {
      handleError(error, showToast);
    }
  };

  const get = async (url: string, params: any, newHeaders: any, showToast: boolean = false): Promise<any> => {
    headers = addCommonHeaders(newHeaders);
    headers = removeHeaderParam(url, headers);

    try {
      const data = await $fetch(url, {
        method: 'GET',
        body: params,
        headers: headers
      });
      return data;
    } catch (error: any) {
      return handleError(error, showToast);
    }
  };

  const patch = async (url: string, params?: any, newHeaders?: any, showToast: boolean = false): Promise<any> => {
    const headers = addCommonHeaders(newHeaders);

    try {
      const data = await $fetch(url, {
        method: 'PATCH',
        body: params,
        headers: headers
      });
      return data;
    } catch (error: any) {
      return handleError(error, showToast);
    }
  };

  const post = async (url: string, params: any, newHeaders: any, showToast: boolean = false): Promise<any> => {
    const headers = addCommonHeaders(newHeaders);
    try {
      const data = await $fetch(url, {
        method: 'POST',
        body: params,
        headers: headers
      });
      return data;
    } catch (error: any) {
      return handleError(error, showToast);
    }
  };

  const deleteRequest = async (url: string, params: any, newHeaders: any, showToast: boolean = false): Promise<any> => {
    const headers = addCommonHeaders(newHeaders);

    try {
      const data = await $fetch(url, {
        method: 'DELETE',
        body: params,
        headers: headers
      });
      return data;
    } catch (error: any) {
      return handleError(error, showToast);
    }
  };

  return {
    usePostFormData,
    get,
    patch,
    post,
    deleteRequest
  };
};
