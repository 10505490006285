import { useUserAuthStore } from '~coreStores/user-auth.store';

export function refreshTokenInterval() {
  const config = useRuntimeConfig();
  const userAuthStore = useUserAuthStore();

  setInterval(() => {
    userAuthStore.refreshTokenAction();
  }, config.public.refreshTokenIntervalTime * 1000);
}
