// Zapamiętuje adres URL, na który próbował wejść użytkownik niezalogowany, aby po zalogowaniu przekierować go na tą stronę.

const storageKeyName = 'redirectToAfterLogin';

export function saveRedirectUrl(value: string) {
  if (!value) return;

  sessionStorage.setItem(storageKeyName, value);
}

export function removeRedirectUrl() {
  sessionStorage.removeItem(storageKeyName);
}

export function getRedirectUrl() {
  return sessionStorage.getItem(storageKeyName);
}
