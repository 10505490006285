import type { FetchContext, ResponseType } from 'ofetch';

import { getActiveStorageContext } from '~coreUtils/context-storage';

import { useUserAuthStore } from '~coreStores/user-auth.store';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const userAuthStore = useUserAuthStore();

  // przygotowuje nagłówki pod zapytania
  function prepareHeaders() {
    const { accessToken } = userAuthStore.getAuthTokens();

    if (!accessToken) return;

    const headers: Record<string, string> = {
      Authorization: `Bearer ${accessToken}`
    };

    const getActiveContext = getActiveStorageContext();
    if (getActiveContext?.office_guid) {
      headers['x-office-guid'] = getActiveContext.office_guid;
      headers['x-currenda-bailiff'] = getActiveContext.office_guid;
    }

    return headers as unknown as Headers; // wymagany typ Headers w funkcji onRequest w ofetch
  }

  // dorzucanie podczas requestu nagłówków np Bearer token
  function onRequest(config: FetchContext<any, ResponseType>) {
    const headers = prepareHeaders();
    if (!headers) return;

    for (const property in headers) {
      if (config.options.headers.has(property)) return;

      config.options.headers.append(property, `${headers[property as keyof typeof headers]}`);
    }
  }

  // do requestów nie wymagających autoryzacji
  const unsecuredAuthFetch = $fetch.create({
    baseURL: `${config.public.apiAuthUrl}`
  });

  // do requestów usług autoryzacji
  const authFetch = $fetch.create({
    baseURL: `${config.public.apiAuthUrl}`,
    onRequest
  });

  // do requestów dla LK bidding-back
  const lkBiddingFetch = $fetch.create({
    baseURL: `${config.public.baseUrlLK}${config.public.biddingServiceLK}`,
    onRequest
  });

  // do requestów dla LK item-back
  const lkItemBackFetch = $fetch.create({
    baseURL: `${config.public.baseUrlLK}${config.public.itemServiceLK}`,
    onRequest
  });

  // do requestów dla LK back-office
  const lkBackOfficeFetch = $fetch.create({
    baseURL: `${config.public.baseUrlLK}${config.public.backOfficeServiceLK}`,
    onRequest
  });

  // do requestów do modułu admin panel
  const adminPanelFetch = $fetch.create({
    baseURL: `${config.public.baseUrlLK}${config.public.adminServiceLK}`
  });

  // do requestów dla OK
  const okFetch = $fetch.create({
    baseURL: config.public.apiOKUrl as string,
    onRequest
  });

  // do requestów MOCK
  const mockFetch = $fetch.create({
    baseURL: config.public.apiMockUrl as string
  });

  // do requestów do STRAPI
  const strapiFetch = $fetch.create({
    baseURL: config.public.strapi as string
  });

  return {
    provide: {
      adminPanelFetch,
      authFetch,
      lkBackOfficeFetch,
      lkBiddingFetch,
      lkItemBackFetch,
      mockFetch,
      okFetch,
      strapiFetch,
      unsecuredAuthFetch
    }
  };
});
