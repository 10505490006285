<script lang="ts" setup>
import { useUserAuthStore } from '~coreStores/user-auth.store';
import { useContextStore } from '~coreStores/context.store';

import { usePageKey } from '~coreComposables/use-page-key';
import { useBailiffAuthTokens } from '~coreComposables/use-bailiff-auth-tokens';
import { useEndSessionCountdown } from '~coreComposables/end-session-countdown.composable';

const BottomBar = defineAsyncComponent(() => import('~coreComponents/BottomBar.vue'));
const BailiffSessionEndedPopup = defineAsyncComponent(
  () => import('~coreComponents/auth/BailiffSessionEndedPopup.vue')
);

const route = useRoute();
const contextStore = useContextStore();
const userAuthStore = useUserAuthStore();
const { getBailiffOfficeUserData, getBidderUserData } = storeToRefs(userAuthStore);
const { refreshTokenBailiff } = useBailiffAuthTokens();
const { $routeNames } = useNuxtApp();
const { handleDocumentVisibilityChange, setLogoutTimer } = useEndSessionCountdown();

// dotyczy tylko komorników/pracowników kancelarii komorniczej
watch(refreshTokenBailiff, async (newToken, oldToken) => {
  if (getBailiffOfficeUserData.value && !newToken) {
    // Usunięcie tokena (wylogowanie) - są dane użytkownika w store w danym tabie przeglądarki

    userAuthStore.clearUserData();

    if (route.meta.layout === 'bailiff' || route.name === $routeNames.bailiff.context) {
      // jeśli użytkownik był na panelu komornika lub wyborze kontekstu to przekieruj go na stronę logowania komornika

      await navigateTo({ name: $routeNames.bailiff.login });
    } else {
      // jeśli użytkownik był na widoku nie związanym z panelem komornika to przekieruj go na stronę główną
      await navigateTo({ name: $routeNames.index });
    }

    contextStore.clearContext();
  } else if (!oldToken && newToken) {
    // Ustawienie tokenu (zalogowanie) - brak danych użytkownika w store w danym tabie przeglądarki

    contextStore.clearContext();

    userAuthStore.setBailiffOfficeUserDataFromToken();

    contextStore.handleContext(route.name === $routeNames.bailiff.login);

    if (route.meta.layout !== 'bailiff' && route.name !== $routeNames.bailiff.login) {
      navigateTo({ name: $routeNames.index });
    }
  }
});

const { pageKey, incrementPageKey } = usePageKey();

// tylko dla komorników/pracowników kancelarii komorniczej
watch(pageKey, () => {
  setLogoutTimer();
});

watch(
  () => [route.name, route.query],
  () => {
    if (getBailiffOfficeUserData.value) {
      incrementPageKey();
    } else if (getBidderUserData.value) {
      setLogoutTimer();
    }
  },
  {
    immediate: true
  }
);

/*
  lista page'ów które mają być trzymane w keepalive. Nazwę definiujemy w page za pomocą:
  defineOptions({
    name: 'bailiffAuctions'
  });
*/
const routesListKeepAlive = ['bailiffAuctions'];

onMounted(() => {
  document.addEventListener('visibilitychange', handleDocumentVisibilityChange);
});
</script>

<template>
  <NuxtLoadingIndicator />

  <CdsApp>
    <NuxtLayout>
      <!-- keepalive dodany tylko do widoku listy aukcji, aby na widokach mobilnych gdzie wyświetlamy listy jako infinity scroll wracanie do list nie powodowało scrolla na samą góre -->
      <NuxtPage :keepalive="{ include: routesListKeepAlive.join(',') }" />

      <BailiffSessionEndedPopup />
    </NuxtLayout>

    <BottomBar />
  </CdsApp>
</template>

<style lang="scss">
@import 'assets/scss/main.scss';
</style>
