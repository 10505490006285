import type {
  IStartAttestationAuthResponse,
  BasePublicKeyCredentialCreationOptions,
  BasePublicKeyCredential,
  BasePublicKeyGetCredential,
  IFinishKeyRegistrationBody
} from '~/types/fido.model';

/*
  Zwraca rpId w oparciu o wartość z .env
  Jeśli nie ma definicji w .env to bierze w oparciu o to co zostanie przekazane w parametrze funkcji - to co backend zwróci.
  Jeśli backend nie zwróci nic to bierze nazwe z objektu window
*/
function getRpId(rpId?: string): string {
  const config = useRuntimeConfig();

  if (config.public.rpId && typeof config.public.rpId === 'string') return config.public.rpId;

  if (rpId) return rpId;

  return window.location.hostname;
}

function stringToBuffer(challenge: string) {
  const base64 = challenge.replace(/-/g, '+').replace(/_/g, '/');
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function arrayBufferToString(data: ArrayBuffer) {
  return btoa(String.fromCharCode(...new Uint8Array(data)));
}

// do mapowania danych pod navigator.credentials.create
export function preformatDataForFidoKey(publicKeyCredentials: BasePublicKeyCredentialCreationOptions) {
  const challenge = stringToBuffer(publicKeyCredentials.challenge);

  const rp = {
    name: publicKeyCredentials.rp.name,
    id: getRpId(publicKeyCredentials.rp.id)
  };

  const user = {
    name: publicKeyCredentials.user.name,
    displayName: publicKeyCredentials.user.name,
    id: stringToBuffer(publicKeyCredentials.user.name)
  };

  return {
    authenticatorSelection: publicKeyCredentials.authenticatorSelection,
    challenge,
    extensions: publicKeyCredentials.extensions,
    pubKeyCredParams: publicKeyCredentials.pubKeyCredParams,
    rp,
    user
  };
}

export function createCredentialsNavigator(
  data: BasePublicKeyCredentialCreationOptions
): Promise<BasePublicKeyCredential | null> {
  return new Promise(async (resolve, reject) => {
    try {
      const publicKey = preformatDataForFidoKey(data);
      const response = await navigator.credentials.create({ publicKey });

      resolve(response as BasePublicKeyCredential);
    } catch (error) {
      reject(error);
    }
  });
}

// do mapowania danych pod navigator.credentials.get
function prepareGetCredentialsPayload(publicKeyCredentials: IStartAttestationAuthResponse) {
  const challenge = stringToBuffer(publicKeyCredentials.challenge);
  const rpId = getRpId(publicKeyCredentials.rpId);
  const allowCredentials = publicKeyCredentials.allowCredentials?.map((cred) => {
    return {
      id: stringToBuffer(cred.id),
      type: cred.type,
      transports: cred.transports
    };
  });

  return {
    challenge,
    allowCredentials,
    rpId
  };
}

export function getCredentialsNavigator(
  data: IStartAttestationAuthResponse
): Promise<BasePublicKeyGetCredential | null> {
  return new Promise(async (resolve, reject) => {
    try {
      const publicKey = prepareGetCredentialsPayload(data);
      const response = await navigator.credentials.get({ publicKey });

      resolve(response as BasePublicKeyGetCredential);
    } catch (error) {
      reject(error);
    }
  });
}

export function createDataFromKeyResponse(data: BasePublicKeyCredential): IFinishKeyRegistrationBody {
  return {
    id: data.id,
    rawId: data.id,
    type: data.type,
    response: {
      attestationObject: arrayBufferToString(data.response.attestationObject),
      clientDataJSON: arrayBufferToString(data.response.clientDataJSON)
    },
    extensions: {}
  };
}
