import { defineStore } from 'pinia';
import type { FetchError } from 'ofetch';

import { base64DecodeUnicode } from '~coreUtils/base64';

import { fetchAnnotationsForAuctionService } from '@Services/auctions/bailiff-auction.service';

import type {
  IBailiffAuctionAnnotation,
  IBailiffAuctionAnnotationsStoreState
} from '@Models/Auctions/BailiffAuction.model';

export const useBailiffAuctionAnnotationsStore = defineStore('bailiffAuctionAnnotations', {
  state: (): IBailiffAuctionAnnotationsStoreState => ({
    annotationFormVisibility: false, // widoczność formularza adnotacji
    annotationText: '', // treść adnotacji
    // stan danych adnotacji dla aukcji
    bailiffAuctionAnnotations: {
      data: null,
      error: null,
      pending: true
    },
    isEditAnnotation: false // czy formularz adnotacji w trybie edycji
  }),
  actions: {
    // otwiera formularz edycji adnotacji
    editAnnotation() {
      if (!this.getBailiffAnnotations.data?.length) return;

      const annotation = this.getBailiffAnnotations?.data[0];

      this.annotationText = annotation.annotationText;
      this.isEditAnnotation = true;

      this.setAnnotationFormVisibility(true);
    },

    // ustawienie widoczności formularza adnotacji
    setAnnotationFormVisibility(visibility: boolean) {
      this.annotationFormVisibility = visibility;
    },

    // dekodowanie wiadomości adnotacji
    decodeAnnotationMessage(annotation: IBailiffAuctionAnnotation) {
      return { ...annotation, annotationText: base64DecodeUnicode(annotation.annotationText) };
    },

    // dodawanie dynamicznie adnotacji
    addAnnotation(annotation: IBailiffAuctionAnnotation) {
      if (!this.bailiffAuctionAnnotations.data) {
        this.bailiffAuctionAnnotations.data = [this.decodeAnnotationMessage(annotation)];

        return;
      }

      this.bailiffAuctionAnnotations.data.unshift(this.decodeAnnotationMessage(annotation));
    },

    // pobiera listę adnotacji dla danej aukcji
    async fetchAuctionAnnotations(auctionId: number) {
      try {
        this.bailiffAuctionAnnotations.pending = true;
        this.bailiffAuctionAnnotations.error = null;

        const response = await fetchAnnotationsForAuctionService(auctionId);

        if (response) {
          this.bailiffAuctionAnnotations.data = response.map((annotation) => {
            return this.decodeAnnotationMessage(annotation);
          });
        } else {
          // jeśli nie ma adnotacji to ustawiamy null, aby nie wyświetlać rekordów z adnotacjami z poprzedniej aukcji na której mogliśm być
          this.bailiffAuctionAnnotations.data = null;
        }
      } catch (err) {
        const error = err as FetchError;
        this.bailiffAuctionAnnotations.error = error;
      } finally {
        this.bailiffAuctionAnnotations.pending = false;
      }
    }
  },
  getters: {
    getBailiffAnnotations: (state) => state.bailiffAuctionAnnotations
  }
});
