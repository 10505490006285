// Zabezpieczenie wszystkich stron oprócz strony wyboru kontekstu.
// Jeśli komornik/pracownik kancelarii komorniczej jest zalogowany i nie ma wybranego kontekstu to przekierowujemy go do wyboru kontekstu.
// W przypadku gdy kontekst jest tylko jeden automatycznie zostaje wybrany.

import { useContextStore } from '~coreStores/context.store';
import { useUserAuthStore } from '~coreStores/user-auth.store';

import { getContextData, removeContextData } from '~coreUtils/auto-set-context-on-redirect';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  const { $routeNames } = useNuxtApp();

  if (to.name === $routeNames.bailiff.context) return;

  const userAuthStore = useUserAuthStore();
  const { getBailiffOfficeUserData } = storeToRefs(userAuthStore);

  const contextStore = useContextStore();
  const { getActiveContext } = storeToRefs(contextStore);

  // jeśli komornik/pracownik kancelarii nie jest zalogowany (nie ma refresh tokena)
  if (getBailiffOfficeUserData.value && !getActiveContext.value) {
    // jeśli chcemy ustawić automatycznie kontekst w oparciu o parametr z query
    const contextData = getContextData();
    if (contextData) {
      contextStore.changeContext(contextData, false);
      removeContextData();
      return;
    }

    return contextStore.handleContext(true, from.query);
  }
});
