export enum EBreakpointsKeys {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl'
}

export const breakpointsList = {
  [EBreakpointsKeys.XS]: 400,
  [EBreakpointsKeys.SM]: 600,
  [EBreakpointsKeys.MD]: 800,
  [EBreakpointsKeys.LG]: 1000,
  [EBreakpointsKeys.XL]: 1300,
  [EBreakpointsKeys.XXL]: 1500
};

export const mobileBreakpoint: EBreakpointsKeys = EBreakpointsKeys.LG;
