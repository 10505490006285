import { pl } from 'vuetify/locale';

import { register } from '../cds-assets/index';

export default defineNuxtPlugin(({ vueApp }) => {
  register(vueApp, {
    vuetify: {
      locale: {
        locale: 'pl',
        messages: { pl }
      }
    }
  });
});
