import { type DataTableSortT } from 'currenda-design-system/dist/types/components/data-display/data-table.types';

export type TSort = DataTableSortT;

export type TSortNotNullable = Exclude<DataTableSortT['direction'], null>;

export enum ESortDirection {
  ASC = 'asc',
  DESC = 'desc'
}
