import { ETokenNames } from '~coreTypes/user-auth.model';

export function useBailiffAuthTokens() {
  const cookiesSameSite = 'lax';
  const cookiesSecure = true;

  // access token komornika, pracownik kancelarii
  const accessTokenBailiff = useCookie(ETokenNames.ACCESS_TOKEN, {
    sameSite: cookiesSameSite,
    secure: cookiesSecure
  });
  // refresh token komornika, pracownik kancelarii
  const refreshTokenBailiff = useCookie(ETokenNames.REFRESH_TOKEN, {
    sameSite: cookiesSameSite,
    secure: cookiesSecure
  });

  // czyści tokeny komornika/pracownika kancelarii
  function clearBailiffAuthTokens() {
    accessTokenBailiff.value = null;
    refreshTokenBailiff.value = null;
  }

  // ustawia tokeny komornika/pracownika kancelarii
  function setBailiffAuthTokens(accessToken: string, refreshToken: string) {
    accessTokenBailiff.value = accessToken;
    refreshTokenBailiff.value = refreshToken;
  }

  return {
    accessTokenBailiff,
    refreshTokenBailiff,
    clearBailiffAuthTokens,
    setBailiffAuthTokens
  };
}
