import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry }
  } = useRuntimeConfig();

  if (!sentry.dsn) return;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [Sentry.browserTracingIntegration({ router })],

    sendDefaultPii: true,
    attachStacktrace: true,

    // Performance Monitoring
    tracesSampleRate: sentry.tracesSampleRate, // 1 - Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [sentry.tracePropagationTargets, /^\//]
  });
});
