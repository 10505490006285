import authenticationStatus from '@Enums/authenticationStatus';
import { useToasts } from '@Composables/toast';

export const useErrorHandler = () => {
  const { $i18n } = useNuxtApp();
  const { setErrorToast } = useToasts();

  const translateError = (data: any, showToast = true) => {
    const errorMessageResponse: any = {
      [authenticationStatus.TOKEN_NOT_FOUND]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.tokenNotExist'
      )}`,
      [authenticationStatus.TOKEN_EXPIRED]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.tokenExpired'
      )}`,
      [authenticationStatus.USER_HAS_NOT_ENABLED_2FA]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.userHasNotEnabled2fa'
      )}`,
      [authenticationStatus.DISABLE_2FA_HAS_ALREADY_BEEN_CONFIRMED]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.disable2faHasAlreadyBeenConfirmed'
      )}`,
      [authenticationStatus.WRONG_SYSTEM_VERSION]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.wrongSystemVersion'
      )}`,
      [authenticationStatus.AUCTION_IS_NOT_EVENTUATED]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.auctionIsNotEventuated'
      )}`,
      [authenticationStatus.AUCTION_SHOULD_HAVE_STATUS_CLOSED]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.auctionShouldHaveStatusClosed'
      )}`,
      [authenticationStatus.AUCTION_CANNOT_BE_ORIDINARY_REAL_ESTATE]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.auctionCannotBeOrdinaryRealEstate'
      )}`,
      [authenticationStatus.USER_IS_NOT_WINNER]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.userIsNotWinner'
      )}`,
      [authenticationStatus.UNAUTHORIZED]: `${$i18n.t('loginForm.twoFactorAuthentication.notification.errors.unauthorized')}`,
      [authenticationStatus.MARGIN_PAYMENT_DEADLINE_HAS_EXPIRED]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.marginPaymentDeadlineHasExpired'
      )}`,
      [authenticationStatus.WAIVER_NOT_FOUND]: `${$i18n.t('errors.waiverNotFound')}`,
      [authenticationStatus.HISTORY_DOWNLOAD_NOT_ALLOWED]: `${$i18n.t('errors.historyDownloadNotAllowed')}`,
      [authenticationStatus.HISTORY_DOWNLOAD_NOT_AVAILABLE]: `${$i18n.t('errors.historyDownloadNotAvailable')}`,
      [authenticationStatus.BAD_CREDENTIALS]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.badPassword'
      )}`,
      [authenticationStatus.EMPTY_KID_RESPONSE]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.emptyKidResponse'
      )}`,
      [authenticationStatus.UNAUTHORIZED_CLIENT]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.badCredentials'
      )}`,
      [authenticationStatus.CAPTCHA_VALIDATION_FAILED]: `${$i18n.t(
        'loginForm.twoFactorAuthentication.notification.errors.captchaValidationFailed'
      )}`,
      [authenticationStatus.BAILIFF_NOT_OWNER]: `${$i18n.t('errors.bailiffNotOwner')}`,
      ['default']: `${$i18n.t('errors.undefinedError')}`
    };

    const message = errorMessageResponse[data] || data;
    if (message && showToast) setErrorToast(message);

    return message;
  };

  return {
    translateError
  };
};
