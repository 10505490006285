/* Helper odpowiadający za:
    - zapisywanie kontekstu w sessionStorage
    - odczytywanie kontekstu z sessionStorage
    - usuwanie kontekstu z sessionStorage
*/

import { type ContextStoreStateI } from '~coreTypes/context.model';

export function getActiveStorageContext() {
  if (!import.meta.client) return null;

  const contextStoreData = sessionStorage.getItem('context');

  if (!contextStoreData) return null;

  const contextParsedStoreData = JSON.parse(contextStoreData) as Pick<ContextStoreStateI, 'activeContext'>;

  return contextParsedStoreData.activeContext;
}
