import type {
  IFinishAttestationAuth,
  IFinishKeyRegistrationBody,
  IStartAttestationAuth,
  MyPublicKeyCredentialCreationOptions
} from '~/types/fido.model';
import type { IResponseLogin } from '~/types/user-auth.model';
import type { IGenericModel } from '~/types/generic.model';

const FIDO_MODULE_NAME = 'fido-second';

export function checkTokenForFido(token: string) {
  const { $authFetch } = useNuxtApp();

  return $authFetch<IResponseLogin>(`${FIDO_MODULE_NAME}/login-fido2`, {
    method: 'POST',
    body: {
      newKey: token
    }
  });
}

export function startFirstKeyRegistration(accessToken: string) {
  const { $authFetch } = useNuxtApp();

  return $authFetch<MyPublicKeyCredentialCreationOptions>(`${FIDO_MODULE_NAME}/start-key-registration`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export function finishFirstKeyRegistration(data: {
  keyResponse: IFinishKeyRegistrationBody;
  accessToken: string;
  session: string;
}) {
  const { $authFetch } = useNuxtApp();

  return $authFetch<IGenericModel<boolean | void>>(`${FIDO_MODULE_NAME}/finish-key-registration`, {
    method: 'POST',
    body: {
      response: data.keyResponse,
      session: data.session
    },
    headers: {
      Authorization: `Bearer ${data.accessToken}`
    }
  });
}

// Usługa do weryfikacji klucza fizycznego (np.YubiKey) - Rozpoczęcie
export function startAttestationAuthService(accessToken: string) {
  const { $authFetch } = useNuxtApp();

  return $authFetch<IStartAttestationAuth>(`${FIDO_MODULE_NAME}/start-attestation-auth`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

// Usługa do weryfikacji klucza fizycznego (np.YubiKey) - Zakończenie
export function finishAttestationAuthService(
  accessToken: string,
  keyResponse: IFinishAttestationAuth,
  session: string
) {
  const { $authFetch } = useNuxtApp();

  return $authFetch<Required<IResponseLogin>>(`${FIDO_MODULE_NAME}/finish-attestation-auth`, {
    method: 'POST',
    body: {
      response: keyResponse,
      session
    },
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}
