import { useUserAuthStore } from '~coreStores/user-auth.store';
import { useContextStore } from '~coreStores/context.store';
import { refreshTokenInterval } from '~coreWorkers/refresh-token';

export default defineNuxtPlugin(async () => {
  const userAuthStore = useUserAuthStore();
  const contextStore = useContextStore();

  const { refreshToken } = userAuthStore.getAuthTokens();

  if (!refreshToken) contextStore.clearContext();

  userAuthStore.setUserDataFromToken();

  await userAuthStore.refreshTokenAction();

  // odświeżąnie tokena dla usera w web worker w folderze o nazwie `workers`
  refreshTokenInterval();
});
