import revive_payload_client_roAYutlgmw from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fac9uqVohB from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ek4sY8DybS from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_aiwkx2Gl4A from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Pm3sXmc5mI from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OgTBkqnhlJ from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SfEQO4kNwK from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mpTpts533Z from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/packages/app/.nuxt/components.plugin.mjs";
import prefetch_client_habICBFoqo from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_edWAHTFnip from "/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_PYjyu3Xzan from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue_6cc402edc64b5b431717c79808b7ec48/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_k1HaboTMiq from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_s0A56P1HFQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import cds_Vgw6JsFlMu from "/app/packages/core/plugins/cds.ts";
import error_pGCEZLjf61 from "/app/packages/core/plugins/error.ts";
import fetch_5BkG0oN1HU from "/app/packages/core/plugins/fetch.ts";
import recaptcha_YlymNOEXOI from "/app/packages/core/plugins/recaptcha.ts";
import routes_Ve1sxvyRqu from "/app/packages/core/plugins/routes.ts";
import sentry_client_eICApkp515 from "/app/packages/core/plugins/sentry.client.ts";
import user_client_9sC4MB84bd from "/app/packages/core/plugins/user.client.ts";
import app_client_R2rCOvV0GX from "/app/packages/lk/plugins/app.client.ts";
import vue_toast_client_In2ERPchzp from "/app/packages/lk/plugins/vue-toast.client.ts";
export default [
  revive_payload_client_roAYutlgmw,
  unhead_fac9uqVohB,
  router_ek4sY8DybS,
  payload_client_aiwkx2Gl4A,
  navigation_repaint_client_Pm3sXmc5mI,
  check_outdated_build_client_OgTBkqnhlJ,
  chunk_reload_client_SfEQO4kNwK,
  plugin_vue3_mpTpts533Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_habICBFoqo,
  plugin_client_edWAHTFnip,
  plugin_PYjyu3Xzan,
  switch_locale_path_ssr_k1HaboTMiq,
  i18n_s0A56P1HFQ,
  cds_Vgw6JsFlMu,
  error_pGCEZLjf61,
  fetch_5BkG0oN1HU,
  recaptcha_YlymNOEXOI,
  routes_Ve1sxvyRqu,
  sentry_client_eICApkp515,
  user_client_9sC4MB84bd,
  app_client_R2rCOvV0GX,
  vue_toast_client_In2ERPchzp
]