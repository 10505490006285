import type { IHeader } from '@Models/header.model';
import header from '@Enums/headers';

import { contentSecurityPolicy, permissionPolicy } from '@/env.js';
import { getCookie, setCookie } from '@Services/helpers';
import { useEnvironment } from '@Composables/environment';

import { useUserAuthStore } from '~coreStores/user-auth.store';

import { getActiveStorageContext } from '~coreUtils/context-storage';

export const useHttpHeadersService: () => any = () => {
  const userAuthStore = useUserAuthStore();

  const excludedPaths = [
    'regulations-lists',
    'private-policy-lists',
    'manual-introductions',
    'manual',
    'faq',
    'topics',
    'trans.do'
  ];

  const addDefaultHeaders = (): IHeader => {
    const runtimeConfig = useRuntimeConfig();

    return {
      [header.ACCEPT]: 'application/json, text/plain, */*',
      [header.ACCEPT_LANGUAGE]: getCookie('i18n_redirected')!,
      [header.CONTENT_TYPE]: 'application/json',
      [header.X_INVISIBLE_RECAPTCHA]: runtimeConfig.public.recaptchaOn
    };
  };

  const addAuthorizationHeaders = (): any => {
    const runtimeConfig = useRuntimeConfig();

    return {
      [header.AUTHORIZATION]: runtimeConfig.public.baseAuth,
      [header.ACCEPT_LANGUAGE]: getCookie('i18n_redirected')!
    };
  };

  const addCommonHeaders = (extraHeaders?: any): IHeader => {
    const headers: IHeader = addDefaultHeaders();
    const { isLocal } = useEnvironment();

    if (!isLocal) {
      headers[header.REFERRER] = 'no-referrer';
      headers[header.PERMISSIONS_POLICY] = permissionPolicy;
      headers[header.CONTENT_SECURITY_POLICY] = contentSecurityPolicy;
    }

    const activeContext = getActiveStorageContext();

    if (process.browser && activeContext?.office_guid) {
      headers[header.X_CURRENDA_BAILIFF] = activeContext.office_guid;
    }

    const { accessToken } = userAuthStore.getAuthTokens();

    if (process.browser && accessToken) {
      headers[header.AUTHORIZATION] = `Bearer ${accessToken}`;
    }

    if (!getCookie('i18n_redirected')) {
      setCookie('i18n_redirected', 'pl', 365);
    }

    if (extraHeaders) {
      return { ...headers, ...extraHeaders };
    }

    return headers;
  };

  const removeHeaderParam = (url: string, headers: IHeader) => {
    if (excludedPaths.find((path) => url.includes(path))) {
      delete headers[header.X_CURRENDA_BAILIFF];
      delete headers[header.AUTHORIZATION];
      delete headers[header.REFERRER];
      delete headers[header.PERMISSIONS_POLICY];
      delete headers[header.CONTENT_SECURITY_POLICY];
      delete headers[header.X_INVISIBLE_RECAPTCHA];
    }

    return headers;
  };

  return {
    addAuthorizationHeaders,
    addCommonHeaders,
    removeHeaderParam,
    addDefaultHeaders
  };
};
