import type { IHeader } from '~/models/header.model';
import authenticationStatus from '@Enums/authenticationStatus';

import { useToasts } from '@Composables/toast';
import { useHttpHeadersService } from '@Composables/httpHeadersService';

export const useHttpServiceV2: () => any = () => {
  const toast = useToasts();
  const { $i18n } = useNuxtApp();
  const { addCommonHeaders, addAuthorizationHeaders } = useHttpHeadersService();

  const usePost = async (url: string, body: any, extraHeaders: Partial<IHeader>): Promise<any> => {
    const headers = addCommonHeaders(extraHeaders);

    try {
      return await $fetch(url, {
        method: 'POST',
        headers,
        body
      });
    } catch (error) {
      handleError(error, true);
      throw error;
    }
  };

  const useGet = async (url: string, extraHeaders: Partial<IHeader> = {}): Promise<any> => {
    const headers = addCommonHeaders(extraHeaders);

    try {
      return await $fetch(url, {
        method: 'GET',
        headers
      });
    } catch (error) {
      handleError(error, true);
      throw error;
    }
  };

  const useAuthorization = async (url: string, body: any): Promise<any> => {
    const headers = addAuthorizationHeaders();

    try {
      return await $fetch(url, {
        method: 'POST',
        headers,
        body
      });
    } catch (error: any) {
      if (error.response._data.error === authenticationStatus.INVALID_GRANT) {
        throw new Error(error.response._data.error, { cause: error });
      } else {
        throw new Error(`Authorization error [${error.response.status}]: ${error.response._data.error}`);
      }
    }
  };

  const useGetFileRaw = async (url: string, params: any | null = null) => {
    const headers = {
      ...addAuthorizationHeaders(),
      ...params
    };

    try {
      return await $fetch(url, {
        method: 'GET',
        headers,
        responseType: 'arrayBuffer'
      });
    } catch (error) {
      handleError(error, true);
      throw error;
    }
  };

  const handleError = (error: any, showToast: boolean): void => {
    if (error.status === 404) {
      toast.setErrorToast($i18n.t('notification.error.message'));
    }
  };

  return {
    useAuthorization,
    usePost,
    useGet,
    useGetFileRaw
  };
};
