enum header {
    ACCEPT = 'Accept',
    ACCEPT_LANGUAGE = 'Accept-Language',
    AUTHORIZATION = 'Authorization',
    CONTENT_TYPE = 'Content-Type',
    ACCESS_CONTROL_ALLOW_ORIGIN = 'Access-Control-Allow-Origin',
    REFERRER = 'Referrer',
    PERMISSIONS_POLICY = 'Permissions-Policy',
    CONTENT_SECURITY_POLICY = 'Content-Security-Policy',
    SYSTEM_VERSION = 'System-Version',
    X_INVISIBLE_RECAPTCHA = 'X-Invisible-Recaptcha',
    X_CURRENDA_RECAPTCHA = 'X-Currenda-Recaptcha',
    X_CURRENDA_BAILIFF = 'X-Currenda-Bailiff',
    X_DEVICE_FINGERPRINT = 'X-Devicefingerprint',
}

export default header;