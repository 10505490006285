import type { IGenericModel } from '@Models/generic.model';
import type { IBailiffAuctionBasic } from '@Models/Auctions/BailiffAuction.model';

export function fetchBailiffAuctionsService(query: any, signal?: AbortSignal) {
  const { $lkItemBackFetch } = useNuxtApp();
  return $lkItemBackFetch<IGenericModel<IBailiffAuctionBasic>>('rest/item/search/bailiff', {
    method: 'GET',
    query: {
      ...query
    },
    signal
  });
}
