import type { TBailiffAuctionOffers } from '@Models/Auctions/BailiffAuctionOffers.model';

// pobieranie listę ofert aukcji
export function fetchOffersForAuctionService(itemId: number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<TBailiffAuctionOffers>('rest/bid/bidders', {
    method: 'GET',
    query: {
      itemId
    }
  });
}
