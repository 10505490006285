/*
    Przy wyjściu z list np listy licytacji zapisujemy parametry np filtry/numer strony/fraze wyszukiwania.
    Dzięki temu w innych widokach można łatwo obsłużyć wracanie do listy z wyklikanymi parametrami.
*/

import { type LocationQueryRaw } from 'vue-router';

const state = reactive({
  savedRouteQuery: undefined as LocationQueryRaw | undefined
});

export function saveRouteQueryOnLeave() {
  const { savedRouteQuery } = toRefs(state);

  const route = useRoute();

  if (route.meta.saveRouteQueryOnLeave) {
    onDeactivated(() => {
      state.savedRouteQuery = route.query;
    });
  }

  return {
    savedRouteQuery
  };
}
