import { defineStore } from 'pinia';
import type { FetchError } from 'ofetch';

import { fetchBailiffAuctionBidderService } from '@Services/auctions/bailiff-auction-bidder.service';

import type { IBailiffAuctionBidderStoreState } from '@Models/Auctions/BailiffAuctionBidder.model';

export const useBailiffAuctionBidderStore = defineStore('bailiffAuctionBidder', {
  state: (): IBailiffAuctionBidderStoreState => ({
    bailiffAuctionBidder: {
      data: null,
      error: null,
      pending: false
    },
    showCallForDocumentsForm: false,
    showCallForOriginalDocumentsForm: false,
    showCallPowerOfAttorney: false,
    showToggleCallForMargin: false,
    showMarginConfirmation: false,
    showOriginalsDocumentsConfirmation: false,
    showOriginalsPrincipalsConfirmation: false,
    showOtherDocumentsConfirmation: false,
    showPowerOfAttorneyConfirmation: false
  }),
  actions: {
    clearBailiffAuctionBidder() {
      this.bailiffAuctionBidder.data = null;
      this.bailiffAuctionBidder.error = null;
    },
    // pobieranie szczegółów o licytancie w danej aukcji (join-info)
    async fetchBailiffAuctionBidder(auctionId: number, bidderId: number, setPendingState = true) {
      const stateKey = 'bailiffAuctionBidder';

      try {
        if (setPendingState) this[stateKey].pending = true;

        const data = await fetchBailiffAuctionBidderService(auctionId, bidderId);

        this[stateKey].error = null;
        this[stateKey].data = data;
      } catch (err: unknown) {
        const error = err as FetchError;
        this[stateKey].data = null;
        this[stateKey].error = error;
        throw new Error(JSON.stringify(error));
      } finally {
        if (setPendingState) this[stateKey].pending = false;
      }
    },
    toggleCallForDocumentVisibility() {
      this.showCallForDocumentsForm = !this.showCallForDocumentsForm;
    },
    toggleCallForOriginalDocumentsVisibility() {
      this.showCallForOriginalDocumentsForm = !this.showCallForOriginalDocumentsForm;
    },
    toggleCallPowerOfAttorney() {
      this.showCallPowerOfAttorney = !this.showCallPowerOfAttorney;
    },
    toggleCallForMargin() {
      this.showToggleCallForMargin = !this.showToggleCallForMargin;
    },
    toggleMarginConfirmation() {
      this.showMarginConfirmation = !this.showMarginConfirmation;
    },
    toggleOtherDocumentsConfirmation() {
      this.showOtherDocumentsConfirmation = !this.showOtherDocumentsConfirmation;
    },
    toggleOriginalsDocumentsConfirmation() {
      this.showOriginalsDocumentsConfirmation = !this.showOriginalsDocumentsConfirmation;
    },
    toggleOriginalsPrincipalsConfirmation() {
      this.showOriginalsPrincipalsConfirmation = !this.showOriginalsPrincipalsConfirmation;
    },
    togglePowerOfAttorneyConfirmation() {
      this.showPowerOfAttorneyConfirmation = !this.showPowerOfAttorneyConfirmation;
    }
  },
  getters: {
    getBailiffAuctionBidder: (state) => state.bailiffAuctionBidder,
    getBailiffAuctionBidderData: (state) => state.bailiffAuctionBidder.data,
    getShowCallForDocumentsForm: (state) => state.showCallForDocumentsForm,
    getShowCallForOriginalDocumentsForm: (state) => state.showCallForOriginalDocumentsForm,
    getShowCallPowerOfAttorney: (state) => state.showCallPowerOfAttorney,
    getShowToggleCallForMargin: (state) => state.showToggleCallForMargin,
    getShowMarginConfirmation: (state) => state.showMarginConfirmation,
    getOriginalsDocumentsConfirmation: (state) => state.showOriginalsDocumentsConfirmation,
    getOriginalsPrincipalsConfirmation: (state) => state.showOriginalsPrincipalsConfirmation,
    getOtherDocumentsConfirmation: (state) => state.showOtherDocumentsConfirmation,
    getPowerOfAttorneyConfirmation: (state) => state.showPowerOfAttorneyConfirmation,
    isBailiffAuctionBidderPending: (state) => state.bailiffAuctionBidder.pending,
    isBidderExcluded: (state) => state.bailiffAuctionBidder.data?.excluded
  }
});
