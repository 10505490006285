enum EAuctionStatus {
  'CREATED' = 'CREATED', // zaplanowana
  'USED' = 'USED', // kiedy ktoś przystąpił, ale licytacja jeszcze nie rozpoczęła się
  'IN_PROGRESS' = 'IN_PROGRESS', // w trakcie
  'KNOCKED' = 'KNOCKED', // przybita
  'CLOSED' = 'CLOSED', // zakończona, ale nie przybita (po przybiciu staje się KNOCKED, ale po odmowie przybicia znowu CLOSED)
  'CANCELLED' = 'CANCELLED', // komornik anulował
  'REMIT_TO_COURT' = 'REMIT_TO_COURT', // przekazana do sądu
  'NOT_EVENTUATED' = 'NOT_EVENTUATED', // nie doszła do skutku
  'CLOSED_WITH_DOC' = 'CLOSED_WITH_DOC', // zakończona z dokumentem
  'CLOSED_NO_DOC' = 'CLOSED_NO_DOC' // zakończona bez dokumentu
}

export { EAuctionStatus };
