import { useToast } from 'vue-toast-notification';

export const useToasts: () => any = () => {
  const toast = useToast();

  const position: string = 'bottom-left';
  const settings = {
    position: position,
    duration: 30000
  } as any;
  const setErrorToast = (message: string, position: string = 'bottom-left') => {
    settings.position = position;
    toast.error(message, settings);
  };

  const setSuccessToast = (message: string, position: string = 'bottom-left') => {
    settings.position = position;
    toast.success(message, settings);
  };

  const setInfoToast = (message: string, position: string = 'bottom-left') => {
    settings.position = position;
    toast.info(message, settings);
  };

  const setWarningToast = (message: string, position: string = 'bottom-left') => {
    settings.position = position;
    toast.warning(message, settings);
  };

  return {
    setSuccessToast,
    setErrorToast,
    setInfoToast,
    setWarningToast
  };
};
