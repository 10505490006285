/* 
  Kontekst jest zapisywany w sessionStorage.
  W związku z tym przy otwieraniu kart w nowym karcie należy zapamiętać kontekst
  w localStorage i odczytywać go w middleware `middleware/kid-context.global.ts`
*/

import type { IContext } from '~coreTypes/context.model';

const storageKeyName = 'contextDataToSetOnRedirect';

// ustawiamy dane kotenkstu w localStorage np po kliknięciu w jakiś link, który otwiera nam nową kartę
export function setContextData(value: string) {
  if (!value) return;

  localStorage.setItem(storageKeyName, value);
}

// odczytujemy dane kontekstu z localStorage w middleware `middleware/kid-context.global.ts`
export function getContextData() {
  const contextData = localStorage.getItem(storageKeyName);
  return contextData ? (JSON.parse(contextData) as IContext) : null;
}

// usuwamy dane kontekstu z localStorage np po odczytaniu danych za pomocą getContextData
export function removeContextData() {
  localStorage.removeItem(storageKeyName);
}
