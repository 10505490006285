import { defineStore } from 'pinia';
import type { LocationQuery } from 'vue-router';

import { EnumRoleName, type ContextStoreStateI, type IContext } from '~coreTypes/context.model';
import { useUserAuthStore } from '~coreStores/user-auth.store';

import { getRedirectUrl, removeRedirectUrl } from '~coreUtils/save-login-redirect';

// Konteksty komornika, pracownika kancelarii komorniczej
export const useContextStore = defineStore('context', {
  state: (): ContextStoreStateI => {
    return {
      activeContext: null
    };
  },
  getters: {
    getActiveContext(state) {
      return state.activeContext;
    },
    // identyfikator aktywnego kontekstu
    getActiveContextIdentify(state) {
      return `${state.activeContext?.office_guid}_${state.activeContext?.role}`;
    },
    getIsBailiff(state) {
      return state.activeContext?.role === EnumRoleName.bailiff;
    },
    getShortContextName(state) {
      const { $i18n } = useNuxtApp();

      const context = state.activeContext;

      if (context?.office_name) {
        const nameSplitted = context.office_name.split(' ');
        const index = nameSplitted.findIndex((el: string) => el === $i18n.t('navbar.office'));
        let shortenName = nameSplitted[0];
        if (index) {
          shortenName += ' (...)';
        }
        for (let i = index; i < nameSplitted.length; i++) {
          shortenName += ' ' + nameSplitted[i];
        }

        return shortenName;
      }

      return null;
    }
  },
  actions: {
    // sprawdza czy aktywny kontekst jest taki sam jak wybrany
    isActiveContextSameAsChosenContext(context: IContext | null) {
      return (
        context &&
        this.getActiveContext?.office_guid === context.office_guid &&
        this.getActiveContext?.role_name === context.role_name
      );
    },

    // przekierowuje do aplikacji
    navigateToApp(query?: LocationQuery) {
      const runtimeConfig = useRuntimeConfig();
      const { $routeNames } = useNuxtApp();

      const redirectUrl = getRedirectUrl();
      if (redirectUrl) {
        removeRedirectUrl();
        navigateTo(redirectUrl);

        return;
      }

      // @TODO usunąć warunek poniżej i przekierowanie do strony głównej po opublikowaniu OK na PROD
      if (runtimeConfig.public.publishedOK) {
        navigateTo({
          name: $routeNames.bailiff.auctions.index,
          query
        });
        return;
      }

      navigateTo({
        name: $routeNames.bailiff.auctions.index,
        query
      });
    },

    // funkcja odpowiadająca za zmiane kontekstu
    changeContext(context: IContext | null, redirect = true, query?: LocationQuery) {
      if (redirect && this.isActiveContextSameAsChosenContext(context)) {
        this.navigateToApp(query);
        return;
      }

      if (!context) {
        this.activeContext = null;
      } else {
        this.activeContext = context;
      }

      if (redirect && context) this.navigateToApp(query);
    },

    // jeśli komornik/pracownik kancelarii ma tylko jeden kontekst to automatycznie zostaje wybierany, w przeciwnym wypadku przekierowujemy do widoku wyboru kontekstu
    handleContext(redirect = true, query?: LocationQuery) {
      const { $routeNames } = useNuxtApp();
      const userAuthStore = useUserAuthStore();

      if (userAuthStore.bailiffOfficeLoginResponse.data?.contexts.length === 1) {
        // wybiera pierwszy kontekst i przekierowuje do aplikacji
        this.changeContext(userAuthStore.bailiffOfficeLoginResponse.data?.contexts[0], redirect, query);
      } else if (userAuthStore.bailiffOfficeLoginResponse.data?.contexts.length) {
        return navigateTo({ name: $routeNames.bailiff.context, query });
      }
    },

    // czyści aktywny kontekst
    clearContext() {
      this.changeContext(null, false);
    }
  },
  ...(import.meta.client && {
    persist: {
      storage: sessionStorage,
      pick: ['activeContext']
    }
  })
});
