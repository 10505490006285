// zaawansowane przekierowania globalne

export default defineNuxtRouteMiddleware((to) => {
  if (!to.path.startsWith('/bailiff/items-details/')) return;

  const { $routeNames } = useNuxtApp();

  const toFragments = to.path.split('/').filter((fragment) => fragment);
  if (!toFragments[2]) return;

  const auctionId = Number(toFragments[2]);

  if (toFragments.length === 5) {
    const id = Number(toFragments[4]);

    if (toFragments.includes('bidder')) {
      /* 
        obsługa przekierowania do zarządzania licytanta ze starego panelu komornika:
        bailiff/items-details/$itemId/bidder/$bidderId na bailiff/auctions/$itemId/bidders/$bidderId
      */
      return navigateTo({
        name: $routeNames.bailiff.auctions.auctionBidder(auctionId, id),
        params: { id: auctionId, bidderId: id }
      });
    } else if (toFragments.includes('complaint')) {
      // obsługa przekierowania do skargi ze starego panelu komornika
      return navigateTo({
        name: $routeNames.bailiff.auctions.auctionComplaint(auctionId, id),
        params: { id: auctionId, complaintId: id }
      });
    }

    // obsługa przekierowania innych adresów do licytacji ze starego panelu komornika
    return navigateTo({
      name: $routeNames.bailiff.auctions.auctionBidders(auctionId),
      params: { id: auctionId }
    });
  }

  // obsługa przekierowania do licytacji ze starego panelu komornika
  return navigateTo({
    name: $routeNames.bailiff.auctions.auctionBidders(auctionId),
    params: { id: auctionId }
  });
});
