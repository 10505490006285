import { EnumWorkingItemsCategoryId } from '~coreTypes/nav.model';

export default defineNuxtPlugin(() => {
  const localeRoute = useLocaleRoute();

  // funkcja do pobierania nazwy route'a po nazwie
  function getLocalizedRouteName(routeName: string, rest = {}) {
    return localeRoute({ name: routeName, ...rest })?.name ?? undefined;
  }

  // lista dostępnych route'ow
  const routeNamesWithoutLocalize = {
    bailiff: {
      announcements: 'bailiff-announcements-category',
      auctions: {
        index: 'bailiff-auctions',
        auctionAnnotations: 'bailiff-auctions-id-annotations',
        auctionBidder: 'bailiff-auctions-id-bidders-bidderId',
        auctionBidders: 'bailiff-auctions-id-bidders',
        auctionComplaints: 'bailiff-auctions-id-complaints',
        auctionComplaint: 'bailiff-auctions-id-complaints-complaintId',
        auctionOffers: 'bailiff-auctions-id-offers'
      },
      bidders: 'bailiff-bidders',
      context: 'bailiff-context',
      editBidderAccount: 'bailiff-edit-bidder-account',
      faqs: 'bailiff-faqs',
      itemBidder: 'bailiff-items-details-itemId-bidder-bidderId',
      itemComplaint: 'bailiff-items-details-itemId-complaint-complaintId',
      itemDetails: 'bailiff-items-details-itemId',
      itemNote: 'bailiff-items-details-itemId-note-noteId',
      login: 'bailiff-login',
      myAccount: 'bailiff-my-account',
      workingItems: 'bailiff-working-items-category'
    },
    disable2FA: 'disable-2fa',
    faq: 'faq-id',
    faqs: 'faqs',
    index: 'index',
    item: 'items-id-slug',
    itemList: 'item-list',
    privacyPolicy: 'privacy-policy',
    register: 'register',
    rules: 'rules',
    safetyRules: 'safety-rules',
    sendReport: 'send-report',
    user: {
      itemComplaint: 'user-items-details-itemId-complaint-complaintId',
      itemDetails: 'user-items-details-itemId',
      itemNote: 'user-items-details-itemId-note-noteId',
      myAccount: 'user-my-account',
      myAuctions: 'user-my-auctions'
    },
    error400: 'error400'
  };

  // lista dostępnych route'ow z localize
  const routeNames = {
    bailiff: {
      auctions: {
        index: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.auctions.index),
        auctionAnnotations: (id: number) =>
          getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.auctions.auctionAnnotations, {
            params: { id }
          }),
        auctionBidder: (id: number, bidderId: number) =>
          getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.auctions.auctionBidder, {
            params: { id, bidderId }
          }),
        auctionBidders: (id: number) =>
          getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.auctions.auctionBidders, {
            params: { id }
          }),
        auctionComplaints: (id: number) =>
          getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.auctions.auctionComplaints, {
            params: { id }
          }),
        auctionComplaint: (auctionId: number, complaintId: number) =>
          getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.auctions.auctionComplaint, {
            params: { id: auctionId, complaintId }
          }),
        auctionOffers: (id: number) =>
          getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.auctions.auctionOffers, {
            params: { id }
          })
      },
      bidders: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.bidders),
      context: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.context),
      editBidderAccount: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.editBidderAccount),
      faqs: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.faqs),
      itemBidder: (itemId: number, bidderId: number) =>
        getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.itemBidder, {
          params: {
            itemId,
            bidderId
          }
        }),
      itemComplaint: (itemId: number, complaintId: number) =>
        getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.itemComplaint, {
          params: {
            itemId,
            complaintId
          }
        }),
      itemDetails: (itemId: number) =>
        getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.itemDetails, {
          params: {
            itemId
          }
        }),
      itemNote: (itemId: number, noteId: number) =>
        getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.itemNote, {
          params: {
            itemId,
            noteId
          }
        }),
      login: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.login),
      myAccount: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.myAccount),
      workingItems: getLocalizedRouteName(routeNamesWithoutLocalize.bailiff.workingItems, {
        params: { category: EnumWorkingItemsCategoryId.REAL_ESTATE }
      })
    },
    disable2FA: getLocalizedRouteName(routeNamesWithoutLocalize.disable2FA),
    faq: (uuid: string) => getLocalizedRouteName(routeNamesWithoutLocalize.faq, { params: { id: uuid } }),
    faqs: getLocalizedRouteName(routeNamesWithoutLocalize.faqs),
    index: getLocalizedRouteName(routeNamesWithoutLocalize.index),
    item: (id: number, slug: string) =>
      getLocalizedRouteName(routeNamesWithoutLocalize.item, {
        params: {
          id,
          slug
        }
      }),
    itemList: getLocalizedRouteName(routeNamesWithoutLocalize.itemList),
    privacyPolicy: getLocalizedRouteName(routeNamesWithoutLocalize.privacyPolicy),
    register: getLocalizedRouteName(routeNamesWithoutLocalize.register),
    rules: getLocalizedRouteName(routeNamesWithoutLocalize.rules),
    safetyRules: getLocalizedRouteName(routeNamesWithoutLocalize.safetyRules),
    sendReport: getLocalizedRouteName(routeNamesWithoutLocalize.sendReport),
    user: {
      itemComplaint: (itemId: number, complaintId: number) =>
        getLocalizedRouteName(routeNamesWithoutLocalize.user.itemComplaint, {
          params: {
            itemId,
            complaintId
          }
        }),
      itemDetails: (itemId: number) =>
        getLocalizedRouteName(routeNamesWithoutLocalize.user.itemDetails, { params: { itemId } }),
      itemNote: (itemId: number, noteId: number) =>
        getLocalizedRouteName(routeNamesWithoutLocalize.user.itemNote, {
          params: {
            itemId,
            noteId
          }
        }),
      myAccount: getLocalizedRouteName(routeNamesWithoutLocalize.user.myAccount),
      myAuctions: getLocalizedRouteName(routeNamesWithoutLocalize.user.myAuctions)
    },
    error400: getLocalizedRouteName(routeNamesWithoutLocalize.error400)
  };

  return {
    provide: {
      routeNames,
      routeNamesWithoutLocalize
    }
  };
});
