import Environment from '@Enums/environment';

export const useEnvironment = () => {
  const isLocal = () => {
    const runtimeConfig = useRuntimeConfig();
    return runtimeConfig.public.environment === Environment.LOCAL;
  };

  const isTest = () => {
    const runtimeConfig = useRuntimeConfig();
    return runtimeConfig.public.environment === Environment.TEST;
  };
  
  const expirationInSec = () => {
    const runtimeConfig = useRuntimeConfig();
    return Number(runtimeConfig.public.expirationInSec);
  };
  
  const isDevelopment  = () => {
    const runtimeConfig = useRuntimeConfig();
    return runtimeConfig.public.environment === Environment.DEVELOPMENT;
  }

  return {
    isTest,
    isDevelopment,
    isLocal,
    expirationInSec
  };
};
