import type { TContextsList } from '~coreTypes/context.model';
import type { ILogin, ILoginGoogleAuth, IResponseLogin } from '~coreTypes/user-auth.model';

const AUTH_MODULE_NAME = 'auth';

export function loginService(body: ILogin) {
  const { $unsecuredAuthFetch } = useNuxtApp();

  return $unsecuredAuthFetch<IResponseLogin>(`/${AUTH_MODULE_NAME}/login`, {
    method: 'POST',
    body
  });
}

export function loginGoogleAuthService(body: ILoginGoogleAuth, verificationAccessToken: string) {
  const { $unsecuredAuthFetch } = useNuxtApp();

  return $unsecuredAuthFetch<IResponseLogin>(`/${AUTH_MODULE_NAME}/login-google-auth`, {
    method: 'POST',
    body,
    headers: {
      Authorization: `Bearer ${verificationAccessToken}`
    }
  });
}

export function refreshTokenService(refreshToken: string) {
  const { $authFetch } = useNuxtApp();

  return $authFetch<IResponseLogin>(`/${AUTH_MODULE_NAME}/refresh-token`, {
    method: 'POST',
    body: {
      refreshToken
    }
  });
}

export function userDataService() {
  const { $authFetch } = useNuxtApp();

  return $authFetch<TContextsList>(`/${AUTH_MODULE_NAME}/session-config`, {
    method: 'GET'
  });
}
