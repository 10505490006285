import type { IGenericState, TErrorWithFetchError } from '~coreTypes/generic-state.model';
import type { TContextsList } from '~coreTypes/context.model';

export enum ETokenNames {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token'
}

export enum ETwoAuthType {
  TWO_AUTH_NONE = 'TWO_AUTH_NONE', // brak 2FA
  TWO_AUTH_GOOGLE_AUTHENTICATOR = 'TWO_AUTH_GOOGLE_AUTHENTICATOR',
  TWO_AUTH_FIDO = 'TWO_AUTH_FIDO'
}

export enum ETokenType {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  VERIFICATION_TOKEN = 'VERIFICATION_TOKEN'
}

export enum ELoginStep {
  LOGIN = 'LOGIN',
  GOOGLE_AUTHENTICATOR = 'GOOGLE_AUTHENTICATOR'
}

// model danych dla logowania użytkownika
export interface ILogin {
  fingerprintOfDevice: string; // dane definiujące zaufane urządzenie - generuje front gdy użytkownik zaznaczy "Zapamiętaj urządzenie" na etapie wpisywania kodu z Google Authenticator
  login: string;
  password: string;
  reCaptcha: string;
  verificationKey?: string;
}

export interface ILoginGoogleAuth {
  addDevice: boolean; // czy dodać urządzenie do listy zaufanych
  deviceName: string; // nazwa pod jaką jest zapisane zaufane urządzenie (unikalna)
  fingerprintDevice: string; // dane definiujące zaufane urządzenie - generuje front gdy użytkownik zaznaczy "Zapamiętaj urządzenie" na etapie wpisywania kodu z Google Authenticator
  twoFactorPin: string; // kod generowany w aplikacji Google Authenticator
}

// model danych odpowiedzi z serwera po zalogowaniu
export interface IResponseLogin {
  accessToken: string | null;
  refreshToken: string | null;
}

// model danych rozkodowanego weryfikacyjnego tokena (user go otrzymuje gdy ma włączone 2FA i musi podać kod uwierzytelniający) dla komornika/pracownika kancelarii komorniczej
export interface IVerificationTokenBailiffOfficeUser {
  aud: string[];
  currenda_id: string;
  exp: number;
  iss: string;
  jti: string;
  token_type: ETokenType; // rodzaj tokenu
  two_auth_status: boolean; // czy zweryfikowane 2FA
  two_auth_type: ETwoAuthType; // rodzaj 2FA
  user_email: string;
  user_guid: string;
  user_name: string;
}

// model danych rozkodowanego access tokena dla komornika/pracownika kancelarii komorniczej
export interface IAccessTokenBailiffOfficeUser extends IVerificationTokenBailiffOfficeUser {
  employment: TContextsList;
}

// model danych rozkodowanego refresh tokena dla komornika/pracownika kancelarii komorniczej
export interface IRefreshTokenBailiffOfficeUser {
  aud: string[];
  exp: number;
  iss: string;
  jti: string;
  refresh_id: string;
  token_type: string;
  user_email: string;
  user_guid: string;
  user_name: string;
}

export interface IResponseRefreshBidderToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
  user_id: string;
}

// model danych rozkodowanego access tokena dla lictytanta
export interface ITokenBidderUser {
  aud: string;
  authorities: string[];
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
  sub: string;
  user_email: string;
  user_fullname: string; // imię i nazwisko
  user_id: number;
  user_name: string;
}

// model danych adresu licytanta
export interface IBidderAddress {
  buildingNo: string;
  city: string;
  community: null | string;
  country: string;
  district: null | string;
  flatNo: string;
  postOffice: string;
  province: null | string;
  street: string;
  streetPrefix: null | string;
  zipCode: string;
}

// model danych użytkownika globalny dla wszystkich typów użytkowników
export interface IUserData {
  email: string;
  name: string;
}

// model danych dla komornika i pracownika kancelarii komorniczej
export interface IBailiffOfficeUserData extends IUserData {
  contexts: TContextsList;
}

// model danych dla licytanta
export interface IBidderUserData extends IUserData {
  address?: IBidderAddress;
  id: null | number;
  legalPersonName?: string | null;
  krs?: string | null;
  nip?: string | null;
  regon?: string | null;
  pesel?: string;
}

// model danych state'u pinia store dla autoryzacji użytkownika
export interface IUserAuthStoreState {
  accessTokenBidder: string;
  bailiffOfficeLoginResponse: IGenericState<IBailiffOfficeUserData, TErrorWithFetchError>;
  bidderLoginResponse: IGenericState<IBidderUserData>;
  login: string;
  loginStep: ELoginStep;
  loginVerificationJWTToken: string | null;
  password: string;
  refreshTokenBidder: string;
  showEndSessionDialog: boolean;
}

// lista kodów błędów zwracanych przez usługe odświezania tokenu
export enum EIDAuthRefreshTokenStatus {
  CONFLICT = -24 // inny komornik zalogował się na konto
}

// lista kodów błędów zwracanych przez usługe do weryfikacji kodu google authenticator
export enum ELoginGoogleAuthErrorStatus {
  DEVICE_NAME_CONFLICT = -21, // nazwa urządzenia już istnieje
  WRONG_PIN = -22 // niepoprawny kod google authenticator
}

// lista kodów błędów zwracanych przez usługe do logowania
export enum ELoginErrorStatus {
  WRONG_LOGIN_OR_PASSWORD = -1,
  REQUIRE_RESET_PASSWORD = -12,
  NO_2FA = -23
}
