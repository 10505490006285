import { default as authorized_46clientHbxFcN5VcAMeta } from "/app/packages/lk/pages/authorized.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as annotations_46clientFoqn54GHTVMeta } from "/app/packages/lk/pages/bailiff/auctions/[id]/annotations.client.vue?macro=true";
import { default as index_46clientN1DGyRKo2wMeta } from "/app/packages/lk/pages/bailiff/auctions/[id]/bidders/[bidderId]/index.client.vue?macro=true";
import { default as index_46clientYoBgNkh4aIMeta } from "/app/packages/lk/pages/bailiff/auctions/[id]/bidders/index.client.vue?macro=true";
import { default as index_46clientmtjwVH0ovAMeta } from "/app/packages/lk/pages/bailiff/auctions/[id]/complaints/[complaintId]/index.client.vue?macro=true";
import { default as index_46clientcx4U8Ui2AWMeta } from "/app/packages/lk/pages/bailiff/auctions/[id]/complaints/index.client.vue?macro=true";
import { default as offers_46client5nPZQtiNRXMeta } from "/app/packages/lk/pages/bailiff/auctions/[id]/offers.client.vue?macro=true";
import { default as _91id_93_46clientJrH3N3NNXBMeta } from "/app/packages/lk/pages/bailiff/auctions/[id].client.vue?macro=true";
import { default as indexfGPR8D7g45Meta } from "/app/packages/lk/pages/bailiff/auctions/index.vue?macro=true";
import { default as context_46client1zjEAk35NgMeta } from "/app/packages/core/pages/bailiff/context.client.vue?macro=true";
import { default as faqsqeIWMCVc1pMeta } from "/app/packages/lk/pages/bailiff/faqs.vue?macro=true";
import { default as login_46clienti0tYtzpFgVMeta } from "/app/packages/core/pages/bailiff/login.client.vue?macro=true";
import { default as change_45password_46client4OaBfyihG9Meta } from "/app/packages/lk/pages/change-password.client.vue?macro=true";
import { default as disable_452faj7IbZ3Vjc5Meta } from "/app/packages/lk/pages/disable-2fa.vue?macro=true";
import { default as error400D61ZpiRAs6Meta } from "/app/packages/lk/pages/error400.vue?macro=true";
import { default as error500GZn1ogNAERMeta } from "/app/packages/lk/pages/error500.vue?macro=true";
import { default as _91id_938OliemFUYwMeta } from "/app/packages/lk/pages/faq/[id].vue?macro=true";
import { default as faqsBXcBKnqa7KMeta } from "/app/packages/lk/pages/faqs.vue?macro=true";
import { default as indexNakTcuZTfUMeta } from "/app/packages/lk/pages/index.vue?macro=true";
import { default as item_45list_46client0dHUmECKpBMeta } from "/app/packages/lk/pages/item-list.client.vue?macro=true";
import { default as _91slug_93_46clientVIuDqg3rsIMeta } from "/app/packages/lk/pages/items/[id]/[slug].client.vue?macro=true";
import { default as _91id_93_46clientshvcttVgE4Meta } from "/app/packages/lk/pages/items/[id].client.vue?macro=true";
import { default as login_45reminderIsSJjpR30yMeta } from "/app/packages/lk/pages/login-reminder.vue?macro=true";
import { default as net_45paymentLa3pXSltAfMeta } from "/app/packages/lk/pages/net-payment.vue?macro=true";
import { default as password_45reminderLrl2FhDrDzMeta } from "/app/packages/lk/pages/password-reminder.vue?macro=true";
import { default as privacy_45policyZWHz4MybQoMeta } from "/app/packages/lk/pages/privacy-policy.vue?macro=true";
import { default as registerMVqzp1OGd9Meta } from "/app/packages/lk/pages/register.vue?macro=true";
import { default as reset_45passwordn80qm9uocBMeta } from "/app/packages/lk/pages/reset-password.vue?macro=true";
import { default as rulesRIuZCSZNgxMeta } from "/app/packages/lk/pages/rules.vue?macro=true";
import { default as safety_45rulesU2tYBQah9oMeta } from "/app/packages/lk/pages/safety-rules.vue?macro=true";
import { default as send_45reportFiMtdmD3ZWMeta } from "/app/packages/lk/pages/send-report.vue?macro=true";
import { default as indexwWFjuZr4upMeta } from "/app/packages/lk/pages/user/complaints/index.vue?macro=true";
import { default as _91complaintId_93aqExDIr0QEMeta } from "/app/packages/lk/pages/user/items-details/[itemId]/complaint/[complaintId].vue?macro=true";
import { default as index_46clientc4JMZmSieZMeta } from "/app/packages/lk/pages/user/items-details/[itemId]/index.client.vue?macro=true";
import { default as _91noteId_93F5RRi5DjIWMeta } from "/app/packages/lk/pages/user/items-details/[itemId]/note/[noteId].vue?macro=true";
import { default as my_45account5pYigu5oFLMeta } from "/app/packages/lk/pages/user/my-account.vue?macro=true";
import { default as my_45auctions7NFkj60eMmMeta } from "/app/packages/lk/pages/user/my-auctions.vue?macro=true";
import { default as verify_45registrationjfU1ZRXbmHMeta } from "/app/packages/lk/pages/verify-registration.vue?macro=true";
import { default as component_45stubQA5XKXpQ4hMeta } from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQA5XKXpQ4h } from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "authorized___pl",
    path: "/authorized",
    component: () => createClientPage(() => import("/app/packages/lk/pages/authorized.client.vue"))
  },
  {
    name: "authorized___en",
    path: "/en/authorized",
    component: () => createClientPage(() => import("/app/packages/lk/pages/authorized.client.vue"))
  },
  {
    name: "bailiff-auctions-id___pl",
    path: "/bailiff/auctions/:id()",
    meta: _91id_93_46clientJrH3N3NNXBMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id].client.vue")),
    children: [
  {
    name: "bailiff-auctions-id-annotations___pl",
    path: "annotations",
    meta: annotations_46clientFoqn54GHTVMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/annotations.client.vue"))
  },
  {
    name: "bailiff-auctions-id-bidders-bidderId___pl",
    path: "bidders/:bidderId()",
    meta: index_46clientN1DGyRKo2wMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/bidders/[bidderId]/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-bidders___pl",
    path: "bidders",
    meta: index_46clientYoBgNkh4aIMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/bidders/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-complaints-complaintId___pl",
    path: "complaints/:complaintId()",
    meta: index_46clientmtjwVH0ovAMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/complaints/[complaintId]/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-complaints___pl",
    path: "complaints",
    meta: index_46clientcx4U8Ui2AWMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/complaints/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-offers___pl",
    path: "offers",
    meta: offers_46client5nPZQtiNRXMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/offers.client.vue"))
  }
]
  },
  {
    name: "bailiff-auctions-id___en",
    path: "/en/bailiff/auctions/:id()",
    meta: _91id_93_46clientJrH3N3NNXBMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id].client.vue")),
    children: [
  {
    name: "bailiff-auctions-id-annotations___en",
    path: "annotations",
    meta: annotations_46clientFoqn54GHTVMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/annotations.client.vue"))
  },
  {
    name: "bailiff-auctions-id-bidders-bidderId___en",
    path: "bidders/:bidderId()",
    meta: index_46clientN1DGyRKo2wMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/bidders/[bidderId]/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-bidders___en",
    path: "bidders",
    meta: index_46clientYoBgNkh4aIMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/bidders/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-complaints-complaintId___en",
    path: "complaints/:complaintId()",
    meta: index_46clientmtjwVH0ovAMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/complaints/[complaintId]/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-complaints___en",
    path: "complaints",
    meta: index_46clientcx4U8Ui2AWMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/complaints/index.client.vue"))
  },
  {
    name: "bailiff-auctions-id-offers___en",
    path: "offers",
    meta: offers_46client5nPZQtiNRXMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/bailiff/auctions/[id]/offers.client.vue"))
  }
]
  },
  {
    name: "bailiff-auctions___pl",
    path: "/bailiff/auctions",
    meta: indexfGPR8D7g45Meta || {},
    component: () => import("/app/packages/lk/pages/bailiff/auctions/index.vue")
  },
  {
    name: "bailiff-auctions___en",
    path: "/en/bailiff/auctions",
    meta: indexfGPR8D7g45Meta || {},
    component: () => import("/app/packages/lk/pages/bailiff/auctions/index.vue")
  },
  {
    name: "bailiff-context___pl",
    path: "/bailiff/context",
    meta: context_46client1zjEAk35NgMeta || {},
    component: () => createClientPage(() => import("/app/packages/core/pages/bailiff/context.client.vue"))
  },
  {
    name: "bailiff-context___en",
    path: "/en/bailiff/context",
    meta: context_46client1zjEAk35NgMeta || {},
    component: () => createClientPage(() => import("/app/packages/core/pages/bailiff/context.client.vue"))
  },
  {
    name: "bailiff-faqs___pl",
    path: "/bailiff/faqs",
    meta: faqsqeIWMCVc1pMeta || {},
    component: () => import("/app/packages/lk/pages/bailiff/faqs.vue")
  },
  {
    name: "bailiff-faqs___en",
    path: "/en/bailiff/faqs",
    meta: faqsqeIWMCVc1pMeta || {},
    component: () => import("/app/packages/lk/pages/bailiff/faqs.vue")
  },
  {
    name: "bailiff-login___pl",
    path: "/bailiff/login",
    meta: login_46clienti0tYtzpFgVMeta || {},
    component: () => createClientPage(() => import("/app/packages/core/pages/bailiff/login.client.vue"))
  },
  {
    name: "bailiff-login___en",
    path: "/en/bailiff/login",
    meta: login_46clienti0tYtzpFgVMeta || {},
    component: () => createClientPage(() => import("/app/packages/core/pages/bailiff/login.client.vue"))
  },
  {
    name: "change-password___pl",
    path: "/change-password",
    meta: change_45password_46client4OaBfyihG9Meta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/change-password.client.vue"))
  },
  {
    name: "change-password___en",
    path: "/en/change-password",
    meta: change_45password_46client4OaBfyihG9Meta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/change-password.client.vue"))
  },
  {
    name: "disable-2fa___pl",
    path: "/disable-2fa",
    component: () => import("/app/packages/lk/pages/disable-2fa.vue")
  },
  {
    name: "disable-2fa___en",
    path: "/en/disable-2fa",
    component: () => import("/app/packages/lk/pages/disable-2fa.vue")
  },
  {
    name: "error400___pl",
    path: "/error400",
    component: () => import("/app/packages/lk/pages/error400.vue")
  },
  {
    name: "error400___en",
    path: "/en/error400",
    component: () => import("/app/packages/lk/pages/error400.vue")
  },
  {
    name: "error500___pl",
    path: "/error500",
    component: () => import("/app/packages/lk/pages/error500.vue")
  },
  {
    name: "error500___en",
    path: "/en/error500",
    component: () => import("/app/packages/lk/pages/error500.vue")
  },
  {
    name: "faq-id___pl",
    path: "/faq/:id()",
    component: () => import("/app/packages/lk/pages/faq/[id].vue")
  },
  {
    name: "faq-id___en",
    path: "/en/faq/:id()",
    component: () => import("/app/packages/lk/pages/faq/[id].vue")
  },
  {
    name: "faqs___pl",
    path: "/faqs",
    component: () => import("/app/packages/lk/pages/faqs.vue")
  },
  {
    name: "faqs___en",
    path: "/en/faqs",
    component: () => import("/app/packages/lk/pages/faqs.vue")
  },
  {
    name: "index___pl",
    path: "/",
    component: () => import("/app/packages/lk/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/packages/lk/pages/index.vue")
  },
  {
    name: "item-list___pl",
    path: "/item-list",
    component: () => createClientPage(() => import("/app/packages/lk/pages/item-list.client.vue"))
  },
  {
    name: "item-list___en",
    path: "/en/item-list",
    component: () => createClientPage(() => import("/app/packages/lk/pages/item-list.client.vue"))
  },
  {
    name: "items-id___pl",
    path: "/items/:id()",
    component: () => createClientPage(() => import("/app/packages/lk/pages/items/[id].client.vue")),
    children: [
  {
    name: "items-id-slug___pl",
    path: ":slug()",
    component: () => createClientPage(() => import("/app/packages/lk/pages/items/[id]/[slug].client.vue"))
  }
]
  },
  {
    name: "items-id___en",
    path: "/en/items/:id()",
    component: () => createClientPage(() => import("/app/packages/lk/pages/items/[id].client.vue")),
    children: [
  {
    name: "items-id-slug___en",
    path: ":slug()",
    component: () => createClientPage(() => import("/app/packages/lk/pages/items/[id]/[slug].client.vue"))
  }
]
  },
  {
    name: "login-reminder___pl",
    path: "/login-reminder",
    component: () => import("/app/packages/lk/pages/login-reminder.vue")
  },
  {
    name: "login-reminder___en",
    path: "/en/login-reminder",
    component: () => import("/app/packages/lk/pages/login-reminder.vue")
  },
  {
    name: "net-payment___pl",
    path: "/net-payment",
    meta: net_45paymentLa3pXSltAfMeta || {},
    component: () => import("/app/packages/lk/pages/net-payment.vue")
  },
  {
    name: "net-payment___en",
    path: "/en/net-payment",
    meta: net_45paymentLa3pXSltAfMeta || {},
    component: () => import("/app/packages/lk/pages/net-payment.vue")
  },
  {
    name: "password-reminder___pl",
    path: "/password-reminder",
    component: () => import("/app/packages/lk/pages/password-reminder.vue")
  },
  {
    name: "password-reminder___en",
    path: "/en/password-reminder",
    component: () => import("/app/packages/lk/pages/password-reminder.vue")
  },
  {
    name: "privacy-policy___pl",
    path: "/privacy-policy",
    component: () => import("/app/packages/lk/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/app/packages/lk/pages/privacy-policy.vue")
  },
  {
    name: "register___pl",
    path: "/register",
    component: () => import("/app/packages/lk/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/app/packages/lk/pages/register.vue")
  },
  {
    name: "reset-password___pl",
    path: "/reset-password",
    meta: reset_45passwordn80qm9uocBMeta || {},
    component: () => import("/app/packages/lk/pages/reset-password.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: reset_45passwordn80qm9uocBMeta || {},
    component: () => import("/app/packages/lk/pages/reset-password.vue")
  },
  {
    name: "rules___pl",
    path: "/rules",
    component: () => import("/app/packages/lk/pages/rules.vue")
  },
  {
    name: "rules___en",
    path: "/en/rules",
    component: () => import("/app/packages/lk/pages/rules.vue")
  },
  {
    name: "safety-rules___pl",
    path: "/safety-rules",
    component: () => import("/app/packages/lk/pages/safety-rules.vue")
  },
  {
    name: "safety-rules___en",
    path: "/en/safety-rules",
    component: () => import("/app/packages/lk/pages/safety-rules.vue")
  },
  {
    name: "send-report___pl",
    path: "/send-report",
    component: () => import("/app/packages/lk/pages/send-report.vue")
  },
  {
    name: "send-report___en",
    path: "/en/send-report",
    component: () => import("/app/packages/lk/pages/send-report.vue")
  },
  {
    name: "user-complaints___pl",
    path: "/user/complaints",
    meta: indexwWFjuZr4upMeta || {},
    component: () => import("/app/packages/lk/pages/user/complaints/index.vue")
  },
  {
    name: "user-complaints___en",
    path: "/en/user/complaints",
    meta: indexwWFjuZr4upMeta || {},
    component: () => import("/app/packages/lk/pages/user/complaints/index.vue")
  },
  {
    name: "user-items-details-itemId-complaint-complaintId___pl",
    path: "/user/items-details/:itemId()/complaint/:complaintId()",
    component: () => import("/app/packages/lk/pages/user/items-details/[itemId]/complaint/[complaintId].vue")
  },
  {
    name: "user-items-details-itemId-complaint-complaintId___en",
    path: "/en/user/items-details/:itemId()/complaint/:complaintId()",
    component: () => import("/app/packages/lk/pages/user/items-details/[itemId]/complaint/[complaintId].vue")
  },
  {
    name: "user-items-details-itemId___pl",
    path: "/user/items-details/:itemId()",
    meta: index_46clientc4JMZmSieZMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/user/items-details/[itemId]/index.client.vue"))
  },
  {
    name: "user-items-details-itemId___en",
    path: "/en/user/items-details/:itemId()",
    meta: index_46clientc4JMZmSieZMeta || {},
    component: () => createClientPage(() => import("/app/packages/lk/pages/user/items-details/[itemId]/index.client.vue"))
  },
  {
    name: "user-items-details-itemId-note-noteId___pl",
    path: "/user/items-details/:itemId()/note/:noteId()",
    component: () => import("/app/packages/lk/pages/user/items-details/[itemId]/note/[noteId].vue")
  },
  {
    name: "user-items-details-itemId-note-noteId___en",
    path: "/en/user/items-details/:itemId()/note/:noteId()",
    component: () => import("/app/packages/lk/pages/user/items-details/[itemId]/note/[noteId].vue")
  },
  {
    name: "user-my-account___pl",
    path: "/user/my-account",
    meta: my_45account5pYigu5oFLMeta || {},
    component: () => import("/app/packages/lk/pages/user/my-account.vue")
  },
  {
    name: "user-my-account___en",
    path: "/en/user/my-account",
    meta: my_45account5pYigu5oFLMeta || {},
    component: () => import("/app/packages/lk/pages/user/my-account.vue")
  },
  {
    name: "user-my-auctions___pl",
    path: "/user/my-auctions",
    meta: my_45auctions7NFkj60eMmMeta || {},
    component: () => import("/app/packages/lk/pages/user/my-auctions.vue")
  },
  {
    name: "user-my-auctions___en",
    path: "/en/user/my-auctions",
    meta: my_45auctions7NFkj60eMmMeta || {},
    component: () => import("/app/packages/lk/pages/user/my-auctions.vue")
  },
  {
    name: "verify-registration___pl",
    path: "/verify-registration",
    component: () => import("/app/packages/lk/pages/verify-registration.vue")
  },
  {
    name: "verify-registration___en",
    path: "/en/verify-registration",
    component: () => import("/app/packages/lk/pages/verify-registration.vue")
  },
  {
    name: component_45stubQA5XKXpQ4hMeta?.name,
    path: "/index.html",
    component: component_45stubQA5XKXpQ4h
  },
  {
    name: component_45stubQA5XKXpQ4hMeta?.name,
    path: "/login",
    component: component_45stubQA5XKXpQ4h
  },
  {
    name: component_45stubQA5XKXpQ4hMeta?.name,
    path: "/bailiff-faqs",
    component: component_45stubQA5XKXpQ4h
  },
  {
    name: component_45stubQA5XKXpQ4hMeta?.name,
    path: "/user-dashboard.html",
    component: component_45stubQA5XKXpQ4h
  }
]