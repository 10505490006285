export const base64DecodeUnicode = (text: string) => {
  if (!text) return '';

  return decodeURIComponent(
    atob(text)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

export const base64EncodeUnicode = (str: string) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(_, p1) {
      return String.fromCharCode(parseInt('0x' + p1, 16));
    })
  );
};
