// Obsługa customowych błędów w aplikacji.

import type { FetchError } from 'ofetch';
import type { NuxtError } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    const err = error as NuxtError<{ isCustomError?: boolean }>;

    // jeśli nie ma isCustomError to pokazujemy error page
    if (!err.data?.isCustomError) {
      showError(err);
    }
  };

  // customowy error ktory nie uruchamia error page, ale zgłasza błąd do sentry
  function createCustomFetchError(error: FetchError) {
    throw createError({
      statusCode: error.statusCode,
      statusMessage: error.message,
      data: {
        isCustomError: true
      }
    });
  }

  return {
    provide: {
      createCustomFetchError
    }
  };
});
