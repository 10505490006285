import { useDebounceFn } from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';
import cloneDeep from 'lodash.clonedeep';

import { fetchBailiffAuctionsService } from '@Services/auctions/bailif-auctions.service';

import { usePLKBreakpoints } from '~coreComposables/breakpoints.composable';

import { camelToSnakeCase } from '~coreUtils/str';

import type { IBailiffAuction } from '@Models/Auctions/BailiffAuction.model';
import type { TSort, TSortNotNullable } from '~coreTypes/sort.model';
import { AlignColumnEnum } from '~coreTypes/table.model';
import { EAuctionStatus } from '@Enums/auctionStatus.model';
import { EActionType } from '@Enums/actionType.model';
import EFilterAuctionsTypes from '@Enums/filterAuctionsTypes';
import EAuctionCategories from '@Enums/auctionCategories';

const initialState = {
  auctions: {
    data: {
      count: 0,
      items: [],
      itemsWithActionCount: 0,
      page: 0,
      pageCount: 0,
      totalCount: 0
    },
    loading: true
  },
  pageNumberMobile: 1
};

const state = reactive<{ auctions: IBailiffAuction; pageNumberMobile: number }>(cloneDeep(initialState));

export function clearBailiffAuctionsState() {
  state.auctions = cloneDeep(initialState.auctions);
  state.pageNumberMobile = initialState.pageNumberMobile;
}

interface IMenuListItem {
  title: string;
  value: string;
}

type TMenuListItems = IMenuListItem[];

// zmienia tablice na string rozdzielony znakiem "|"
function mapValueForRouteQuery(value: string[]) {
  return value.join('|');
}

// rozdziela stringi które są podzielone znakiem "|" na tablice i zmienia aby tablica była jedno wymiarowa
function mapValueForService(value: string[]) {
  return value.map((el) => el.split('|')).flat();
}

// funkcja reużywalna do transformacji wartości z query
export function routeQueryTransformHelper(value: string[] | string) {
  return Array.isArray(value) ? value : [value];
}

// mapuje zwracaną wartość przez komponent CdsMenuList/CdsChipGroup, ponieważ chcemy mieć tablicę jednowymiarową
export function handleUpdateListModelValue(value: unknown) {
  if (!Array.isArray(value)) return [];

  return value.flat();
}

export function useBailiffAuctionsList() {
  const { auctions, pageNumberMobile } = toRefs(state);

  const { isMobile } = usePLKBreakpoints();
  const { $i18n, $routeNames } = useNuxtApp();

  // ogólna lista statusów aukcji po których można filtrować na wersji desktop & mobile
  const auctionStatusBase: TMenuListItems = [
    {
      title: $i18n.t('subheader.scheduled'),
      value: mapValueForRouteQuery([EAuctionStatus.CREATED, EAuctionStatus.USED])
    },
    {
      title: $i18n.t('subheader.current'),
      value: EAuctionStatus.IN_PROGRESS
    },
    {
      title: $i18n.t('subheader.notEventuatedList'),
      value: EAuctionStatus.NOT_EVENTUATED
    },
    {
      title: $i18n.t('subheader.cancelled'),
      value: EAuctionStatus.CANCELLED
    }
  ];

  // lista statusów aukcji po których można filtrować na wersji mobile
  const auctionStatusesMobile: TMenuListItems = [
    ...auctionStatusBase,
    {
      title: $i18n.t('subheader.ended'),
      value: mapValueForRouteQuery([EAuctionStatus.CLOSED_NO_DOC, EAuctionStatus.CLOSED_WITH_DOC])
    }
  ];

  // lista statusów aukcji po których można filtrować na wersji desktop
  const auctionStatusesDevice: TMenuListItems = [
    ...auctionStatusBase,
    {
      title: $i18n.t('bailiffItemsList.auctionStatus.endedWithoutDocs'),
      value: EAuctionStatus.CLOSED_NO_DOC
    },
    {
      title: $i18n.t('bailiffItemsList.auctionStatus.endedWithDocs'),
      value: EAuctionStatus.CLOSED_WITH_DOC
    }
  ];

  const actionTypesByBailiff = mapValueForRouteQuery([
    EActionType.CONFIRM_MARGIN_PAYMENT,
    EActionType.CONFIRM_ITEM_PAYMENT,
    EActionType.CONFIRM_PRINCIPAL,
    EActionType.CONFIRM_OTHER_DOCUMENT,
    EActionType.COMPLAINT_RECOGNIZE,
    EActionType.COMPLAINT_DECISION,
    EActionType.CONFIRM_WAIVER
  ]);

  // lista opcji "licytacja wymaga" po których można filtrować na wersji desktop
  const actionTypesDevice: TMenuListItems = [
    {
      title: $i18n.t('bailiffItemsList.actionType.bidders'),
      value: actionTypesByBailiff
    },
    {
      title: $i18n.t('bailiffItemsList.actionType.bailiff'),
      value: EActionType.KNOCK_DOWN
    },
    {
      title: $i18n.t('bailiffItemsList.actionType.court'),
      value: EActionType.REMIT_TO_COURT
    }
  ];

  // lista opcji "licytacja wymaga" po których można filtrować na wersji mobilnej
  const actionTypesMobile: TMenuListItems = [
    {
      title: $i18n.t('bailiffItemsList.actionType.bidders'),
      value: actionTypesByBailiff
    },
    {
      title: $i18n.t('bailiffItemsList.actionType.nailed'),
      value: mapValueForRouteQuery([EActionType.KNOCK_DOWN, EActionType.REMIT_TO_COURT])
    }
  ];

  // lista kategorii aukcji po których można filtrować
  const categoriesItems: TMenuListItems = [
    {
      title: $i18n.t('bailiffItemsList.categories.realEstate'),
      value: EAuctionCategories.REAL_ESTATE
    },
    {
      title: $i18n.t('bailiffItemsList.categories.movable'),
      value: EAuctionCategories.MOVABLE
    }
  ];

  const pageNo = useRouteQuery('pageNo', '1', { transform: Number, mode: 'push' });

  const limit = useRouteQuery('limit', '20', { transform: Number, mode: 'push' });

  const recordsPerPageOptions = [
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ];

  const headers = [
    {
      title: 'ID',
      key: 'id',
      width: 70
    },
    {
      title: '',
      key: 'actionRequired',
      sortable: true,
      width: 80,
      align: AlignColumnEnum.center
    },
    {
      title: '',
      key: 'photo',
      width: 54,
      align: AlignColumnEnum.center
    },
    {
      title: $i18n.t('bailiffItemsList.table.title'),
      minWidth: 150,
      key: 'title'
    },
    {
      title: $i18n.t('bailiffItemsList.table.signature'),
      key: 'caseSignature',
      width: 190
    },
    {
      title: $i18n.t('bailiffItemsList.table.startAt'),
      key: 'startAuction',
      width: 140
    },
    {
      title: $i18n.t('bailiffItemsList.table.closeAt'),
      key: 'endAuction',
      width: 140,
      align: AlignColumnEnum.end
    },
    {
      title: $i18n.t('bailiffItemsList.table.status'),
      key: 'auctionStatus',
      minWidth: 160,
      width: 190,
      align: AlignColumnEnum.end
    }
  ];

  // wyszukiwanie po treści
  const searchPhrase = useRouteQuery<string>('searchPhrase', undefined, { mode: 'push' });

  const debounceSearch = useDebounceFn((value: string) => {
    searchPhrase.value = value || '';
  }, 350);

  function clearSearchPhrase() {
    searchPhrase.value = '';
  }

  // wybrane kategorie aukcji w filtrach
  const selectedCategories = useRouteQuery<string[]>('categories[]', [], {
    transform: (value) => routeQueryTransformHelper(value),
    mode: 'push'
  });

  // wybrane akcje w filtrach (licytacja wymaga)
  const selectedActionTypes = useRouteQuery<string[]>('actionTypes[]', [], {
    transform: (value) => routeQueryTransformHelper(value),
    mode: 'push'
  });

  // wybrane statusy licytacji w filtrach
  const selectedAuctionStatuses = useRouteQuery<string[]>('auctionStatus[]', [], {
    transform: (value) => routeQueryTransformHelper(value),
    mode: 'push'
  });

  const sortBy = useRouteQuery<string | null>('sortBy', null, { mode: 'push' });
  const sortDirection = useRouteQuery<TSortNotNullable | null>('sortDirection', null, {
    mode: 'push'
  });

  // czyści filtr kategorie
  function removeCategoriesSelected() {
    selectedCategories.value = [];
  }

  // czyści filtr licytacja wymaga
  function removeActionTypeSelected() {
    selectedActionTypes.value = [];
  }

  // czyści filtr status aukcji
  function removeAuctionStatusSelected() {
    selectedAuctionStatuses.value = [];
  }

  function restartPageMobile() {
    pageNumberMobile.value = 1;
  }

  // usuwa wszystkie filtry
  const removeAllFilters = () => {
    removeCategoriesSelected();
    removeActionTypeSelected();
    removeAuctionStatusSelected();
  };

  let abortController: AbortController | null = null;

  // pobiera listę aukcji zależnie od urządzenia desktop/mobile
  const fetchAuctionsByDevice = async (restartPage = false) => {
    if (abortController) abortController.abort();

    abortController = new AbortController();

    if (isMobile.value) {
      if (restartPage) restartPageMobile();
      auctions.value.loading = true;
      await fetchAuctionsForInfinityScroll(true);
    } else {
      if (restartPage) changePageNumber(1);
      await fetchAuctionsList();
    }
  };

  // przygotowuje parametry query do fetchowania listy aukcji
  const prepareDateForFetchAuctions = () => {
    const data: Record<string, any> = {
      limit: limit.value,
      filter: {}
    };

    if (sortBy.value && sortDirection.value) {
      data.filter.sortBy = {
        [sortBy.value]: sortDirection.value
      };
    }

    if (selectedCategories.value.length) {
      data.filter.where = {
        [EFilterAuctionsTypes.AUCTION_CATEGORY]: mapValueForService(selectedCategories.value)
      };
    }

    if (selectedActionTypes.value.length) {
      data.filter.where = {
        ...data.filter.where,
        [EFilterAuctionsTypes.ACTION_TYPE]: mapValueForService(selectedActionTypes.value)
      };
    }

    if (selectedAuctionStatuses.value.length) {
      data.filter.where = {
        ...data.filter.where,
        [EFilterAuctionsTypes.AUCTION_STATUS]: mapValueForService(selectedAuctionStatuses.value)
      };
    }

    if (searchPhrase.value) {
      data.filter.where = {
        ...data.filter.where,
        [EFilterAuctionsTypes.TITLE]: searchPhrase.value
      };
    }

    return data;
  };

  const fetchAuctionsList = async () => {
    auctions.value.loading = true;
    const data = prepareDateForFetchAuctions();
    data.pageNo = pageNo.value;

    const response = await fetchBailiffAuctionsService(data, abortController?.signal);
    if (response.ok) auctions.value.data = response.object;
    auctions.value.loading = false;
  };

  const fetchAuctionsForInfinityScroll = async (withFilters?: boolean) => {
    const data = prepareDateForFetchAuctions();
    data.pageNo = pageNumberMobile.value;
    const response = await fetchBailiffAuctionsService(data, abortController?.signal);
    if (response.ok) {
      if (withFilters) {
        auctions.value.data = response.object;
      } else {
        const allItems = [...auctions.value.data.items, ...response.object.items];
        auctions.value.data = response.object;
        auctions.value.data.items = allItems;
      }

      auctions.value.loading = false;
    }
  };

  const changeSort = async (sortData: TSort) => {
    if (sortData.direction) {
      sortBy.value = camelToSnakeCase(sortData.key);
      sortDirection.value = sortData.direction;
    } else {
      // reset do domyślnego sortowania
      sortBy.value = null;
      sortDirection.value = null;
    }
  };

  function redirectToItem(item: Record<string, any>) {
    return { name: $routeNames.bailiff.auctions.auctionBidders(item.id), params: { id: item.id } };
  }

  const changePageNumber = async (newPage: number) => {
    pageNo.value = newPage;
  };

  return {
    auctions,
    actionTypesDevice,
    actionTypesMobile,
    auctionStatusesDevice,
    auctionStatusesMobile,
    categoriesItems,
    headers,
    limit,
    pageNo,
    pageNumberMobile,
    recordsPerPageOptions,
    searchPhrase,
    selectedActionTypes,
    selectedAuctionStatuses,
    selectedCategories,
    changePageNumber,
    changeSort,
    clearSearchPhrase,
    debounceSearch,
    fetchAuctionsByDevice,
    fetchAuctionsForInfinityScroll,
    fetchAuctionsList,
    redirectToItem,
    removeActionTypeSelected,
    removeAllFilters,
    removeAuctionStatusSelected,
    removeCategoriesSelected
  };
}
