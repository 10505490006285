import { useBreakpoints } from '@vueuse/core';

import { breakpointsList, mobileBreakpoint, EBreakpointsKeys } from '~coreUtils/breakpoints';

export function usePLKBreakpoints() {
  const breakpoints = useBreakpoints(breakpointsList);

  const isMobile = computed(() => !breakpoints.current().value.includes(mobileBreakpoint));

  const isBelowSm = computed(() => !breakpoints.current().value.includes(EBreakpointsKeys.SM));

  const xxl = computed(() => breakpoints.current().value.includes(EBreakpointsKeys.XXL));

  return {
    isBelowSm,
    isMobile,
    xxl
  };
}
