import validate from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/pages/runtime/validate.js";
import kid_45context_45global from "/app/packages/core/middleware/kid-context.global.ts";
import redirects_45global from "/app/packages/core/middleware/redirects.global.ts";
import clear_45state_45global from "/app/packages/lk/middleware/clear-state.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_iored_df5c97db4e01b334db32550d19e14b9f/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  kid_45context_45global,
  redirects_45global,
  clear_45state_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "bidder-auth": () => import("/app/packages/core/middleware/bidder-auth.ts"),
  "kid-auth": () => import("/app/packages/core/middleware/kid-auth.ts"),
  "kid-autologin": () => import("/app/packages/core/middleware/kid-autologin.ts"),
  "query-has-token": () => import("/app/packages/core/middleware/query-has-token.ts"),
  "user-auth": () => import("/app/packages/core/middleware/user-auth.ts")
}